import React, { useState, useEffect, useMemo, useRef } from "react";
import Logo from "../assets/images/texlang-Logo.svg";
import {
  LogOut,
  DoneStatus,
  ErrorStatus,
  AddSpeakerBtn,
  PopupClose,
  SignOut,
  SpeakerRemove,
  ErrorIcon,
} from "./icons.component";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";
import { format } from "date-fns";
import Peaks from "peaks.js";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import validate from "./Validations/msvalidation";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import GuidelinesContent from "./Popups/megdapguidelines";
import KeyShortcutContents from "./Popups/keyboardshortcuts";
import AddSpeakerContent from "./Popups/addspeaker";
import ProofReadSubmit from "./Popups/proofreadSubmit";
//import FindAndReplace from "./Popups/findAndReplace"
import { useSelector } from "react-redux";
import { useBeforeunload } from "react-beforeunload";
import IdleTimer from "react-idle-timer";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProfile from "./userProfile";
import Spinner from "./spinner"
import { useDispatch } from "react-redux";
import { logout } from "../actions/userActions.js";
//import Konva from "konva"
//import RightClickMenu from '../helper/RightClickMenu.js'
//import "@webscopeio/react-textarea-autocomplete/style.css";

Modal.setAppElement("#root");

const MsEditor = (props) => {
  //React Hooks
  const dispatch = useDispatch();
  const [datalist, setDataList] = useState([]);
  const [readJson, setReadJson] = useState([]);
  const [urlPath, setUrlPath] = useState();
  const [arrayAudioBuffer, setArrayAudioBuffer] = useState();
  const [addedSegments, setAddedSegments] = useState([]);
  const [allPeaks, setAllPeaks] = useState(null);
  //const [selectSegment, setSelectSegment] = useState([]);
  const [mergeSegment, setMergeSegment] = useState([]);
  const [pane, setPane] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [segmentError, setSegmentError] = useState([]);
  const [isplay, setIsPlay] = useState(false);
  const [flag, setFlag] = useState();
  //const [fileopenstatus, setFileopenstatus] = useState(false);
  const [accessDetails, setAccessDetails] = useState({
    accessedIP: "",
    accessedBy: "",
    accessedOn: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowAdded, setRowAdded] = useState([]);
  const [altSegStartTime, setAltSegStartTime] = useState()
  const [altSegEndTime, setAltSegEndTime] = useState()
  const [altSegId, setAltSegId] = useState()
  const [altsegspeaker,setAltSegSpeaker]=useState("");
  const [subsegtext,setSubSegText]=useState("");
  const [subsegarray,setSubSegArray]=useState([{}]);
  const [pressedKeys, setPressedKeys] = useState([]);
  const [currentSegId, setCurrentSegId] = useState();
  const [spelltext, setSpellText] = useState([])
  const [notify, setNotify] = useState("")
  //let [isProofRead, setIsProofRead] = useState(false);

  const ALLOWED_KEYS = ['Enter', 'tab']

  const angularTags = [
    {name: "AW",string: "<AW></AW>",},
    {name: "CP",string: "<CP></CP>",},
    {name: "FIL",string: "<FIL/>",},
    {name: "LM",string: "<LM></LM>",},
    {name: "MP",string: "<MP></MP>",},
    {name: "NPS",string: "<NPS></NPS>",},
    // {name: "OVERLAP",string: "<OVERLAP></OVERLAP>",},
    {name: "SIL",string: "<SIL>",},
    {name: "SUP",string: "<SUP></SUP>",},
    {name: "UNIN",string: "<UNIN/>",},
    {name: "UNKNOWN",string: "<UNKNOWN/>",},
    {name: "MUSIC",string: "<MUSIC></MUSIC>",},
    ];
  
  const slashTags = [
    { name: "PERIOD", string: "\\PERIOD" },
    { name: "FULL_STOP",string: "\\FULL_STOP",},
    { name: "NEW_LINE", string: "\\NEW_LINE" },
    { name: "NEW_PARAGRAPH",string: "\\NEW_PARAGRAPH",},
    { name: "COMMA", string: "\\COMMA" },
    { name: "QUESTION_MARK",string: "\\QUESTION_MARK",},
    { name: "EXCLAMATION_MARK",string: "\\EXCLAMATION_MARK",},
    { name: "EXCLAMATION_POINT",string: "\\EXCLAMATION_POINT",},
    { name: "COLON", string: "\\COLON" },
    { name: "SEMI_COLON",string: "\\SEMI_COLON",},
    { name: "QUOTE", string: "\\QUOTE" },
    { name: "UNQUOTE", string: "\\UNQUOTE" },
    { name: "QUOTATION_MARK",string: "\\QUOTATION_MARK",},
    { name: "OPEN_QUOTE",string: "\\OPEN_QUOTE",},
    { name: "CLOSE_QUOTE",string: "\\CLOSE_QUOTE",},
    { name: "OPEN_QUOTATION_MARK",string: "\\OPEN_QUOTATION_MARK",},
    { name: "CLOSE_QUOTATION_MARK",string: "\\CLOSE_QUOTATION_MARK",},
    ];

  //Props
  let id = props.match.params.id;
  //console.log("id", id)

  //Logged In Users Information
  const userLogin = useSelector((state) => state.userLogin);

  //console.log("userInfo is ", userLogin.user.userType);

  const { id: userID, userType } = userLogin.user;

  //console.log("userType", userType)

  const { ipaddr } = userLogin;
  
  
  window.addEventListener("storage", (event)=>{
      console.log("logout-event")
      if (event.key == 'logout-event') {
        dispatch(logout())
      }
  })
  

  const stickyWave = document.getElementById("stickywave");
  const segmentcontainer = document.getElementById("segmentwrap");
  const sticky = stickyWave && stickyWave.offsetTop;

  useEffect(()=>{
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        stickyWave && stickyWave.classList.add("sticky");
        segmentcontainer && segmentcontainer.classList.add("segmentcontainer-mar-top")
      } else {
        stickyWave && stickyWave.classList.remove("sticky");
        segmentcontainer && segmentcontainer.classList.remove("segmentcontainer-mar-top")
      }
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    });
  },[sticky])


  //check isfileAccessed flag status and close window if 'true' else set flag as 'true' and continue transcription work
  useEffect(() => {
    async function checkisFileAccessed() {
      let url = "";

      const headers = reqHeader(true, true, true);
      const params = {
        userID: userID,
        accessedIP: ipaddr,
        accessedOn: accessDetails.accessedOn,
      };
      const config = { headers, params };

      url = `${process.env.REACT_APP_URL}/transcription/editor/isfileaccessed/${id}`;

      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res inside checkisFileAccessed is ", res);
          if (res.data.data) {
            //setFileopenstatus(true);
            //toast.error("File is already Opened.");
            alert("File is already open by another user.");
            window.open("", "_parent", "");
            window.close();
          } else {
            const body = {
              type: "onOpen",
              isfileAccessed: true,
              accessedBy: userID,
              accessedIP: ipaddr,
            };
            url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
            const params = {
              accessedIP: accessDetails.accessedIP,
              accessedBy: accessDetails.accessedBy,
              accessedOn: accessDetails.accessedOn,
            };

            const config = { headers, params };

            axios
              .put(url, body, config)
              .then((res) => {
                //console.log("Updated file access status to true");

                const { data } = res.data;
                //console.log("Updated data is ", data);

                setAccessDetails({
                  accessedIP: data.accessedIP,
                  accessedBy: data.accessedBy,
                  accessedOn: data.accessedOn,
                });
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    checkisFileAccessed();
  }, []);

  useBeforeunload((event) => {
    if (
      accessDetails.accessedIP !== "" &&
      accessDetails.accessedBy !== "" &&
      accessDetails.accessedOn !== ""
    ) {
      window.onbeforeunload = null;

      event.preventDefault();

      const url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
      const headers = reqHeader(true, true, true);
      const params = {
        accessedIP: accessDetails.accessedIP,
        accessedBy: accessDetails.accessedBy,
        accessedOn: accessDetails.accessedOn,
        accessDetails: accessDetails,
      };
      const config = { headers, params };
      const body = {
        type: "onClose",
        isfileAccessed: false,
        accessedIP: "",
        accessedBy: "",
      };
      axios
        .put(url, body, config)
        .then((res) => {
          console.log("Updated file access status to false");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  });

  const accessDetRef = useRef({
    accessedIP: "",
    accessedBy: "",
    accessedOn: "",
  });

  useEffect(() => {
    accessDetRef.current = accessDetails;
  }, [accessDetails]);

  // reset isfileAccessed flag as false when window is getting closed
  useEffect(() => {
    const onbeforeunloadFn = () => {
      //localStorage.setItem('color', 'red')

      //console.log("window is closing");
      const url = `${process.env.REACT_APP_URL}/transcription/editor/updatefileaccessedstatus/${id}`;
      const headers = reqHeader(true, true, true);
      const params = {
        accessedIP: accessDetRef.current.accessedIP,
        accessedBy: accessDetRef.current.accessedBy,
        accessedOn: accessDetRef.current.accessedOn,
        accessDetails: accessDetRef.current,
      };
      const config = { headers, params };
      const body = {
        type: "onClose",
        isfileAccessed: false,
        accessedIP: "",
        accessedBy: "",
      };
      axios
        .put(url, body, config)
        .then((res) => {
          //console.log("Updated file access status to false");
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    window.addEventListener("beforeunload", onbeforeunloadFn);
    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn);
    };
  }, []);

  //FileDetails and ReadAudio Functions
  useEffect(() => {
    console.log("Inside read audio")
    async function getFilleDetails() {
      let url = "";

      const headers = reqHeader(true, true, true);
      const config = { headers };

      url = `${process.env.REACT_APP_URL}/transcription/editor/readAudio/${id}`;

      await axios
        .get(url, config)
        .then((res) => {
          //console.log("datalist res", res)
          //log.info(res.data.data)
          setDataList(res.data.data);
          setReadJson(res.data.buffer);
        })
        .catch((err) => {
          console.log("catch err", err)
          //log.error(err);
        });
    }
    getFilleDetails();

    async function readAudioFile() {
      const AudioFileurl = `${process.env.REACT_APP_URL}/transcription/transcription/previewAudioFiles`;
      const headers = reqHeader(true, true, true);
      console.log("datalist", datalist)
      //var revisedUrl =
        //datalist.fileLocation &&
        //datalist.fileLocation.replace("/TexLang-Cloud-Storage/", "/");
        console.log("datalist", datalist.fileLocation)
        let revisedUrl = process.env.REACT_APP_ENVIRONMENT === "production"
        ? datalist.fileLocation && datalist.fileLocation.replace("/texlang-cloud-storage/", "/")
        : datalist.fileLocation && datalist.fileLocation.replace("/staging-texlang-cloudstorage/", "/");
        console.log("revisedUrl", revisedUrl);
        const params = {
          fpath: revisedUrl && revisedUrl,
          type: "Transcription",
        };

      const config = { headers, params };

      if (datalist.fileLocation && revisedUrl) {
        await axios
          .get(AudioFileurl, config)
          .then((response) => {
            console.log("catch response", response)
            const { url } = response.data.data;
            setUrlPath(url);
          })
          .catch((err) => {
            console.log("catch err", err)

            //err ? log.error(err) : console.log("catch err", err);
             //log.info("one");
          });
      }
    }
    readAudioFile();
  }, [datalist.fileLocation]);

  //Convert AudioContext into AudioBuffer
  useEffect(() => {
    async function audioArrayBuffer() {
      let audioCtx;
      if (window.webkitAudioContext) {
        audioCtx = new window.webkitAudioContext();
      } else {
        audioCtx = new window.AudioContext();
      }
      let source;
      source = audioCtx.createBufferSource();
      let request = new XMLHttpRequest();

      request.open("GET", urlPath, true);

      request.responseType = "arraybuffer";
      request.onload = function () {
        var audioData = request.response;

        audioCtx.decodeAudioData(
          audioData,
          function (buffer) {
            source.buffer = buffer;
            setArrayAudioBuffer(source.buffer);
            //source.connect(audioCtx.destination);
            //source.loop = true;
          },

          function (e) {
            console.log("Error with decoding audio data" + e.err);
          }
        );
      };
      request.send();
    }

    if (urlPath) {
      audioArrayBuffer();
    }
  }, [urlPath]);

  //Peaks.js Implementation
  useEffect(() => {
    if (arrayAudioBuffer) {
      const options = {
        containers: {
          overview: document.getElementById("overview-container"),
          zoomview: document.getElementById("zoomview-container"),
        },
        mediaElement: document.querySelector("audio"),
        webAudio: {
          audioBuffer: arrayAudioBuffer,
        },
        keyboard: true,
        emitCueEvents: true,
        height: 130,
        pointMarkerColor: "red",
        randomizeSegmentColor: false,
        showPlayheadTime: true,
        playheadColor: "red",
        playheadTextColor: "red",
        zoomLevels: [512, 1024, 2048, 4096],
        zoomWaveformColor: "rgba(0, 0, 0, 1)",
        segmentColor: "rgba(0, 0, 0, 1)",
      };

      Peaks.init(options, function (err, peaksInstance) {
        //console.log("peaks", peaksInstance);
        setAddedSegments([]);
        setMergeSegment([]);
        setFormErrors([]);
        setAllPeaks(peaksInstance);

        const Jsonparse = readJson && JSON.parse(readJson);
        //console.log("Jsonparse", Jsonparse);
        if (Jsonparse) {
          Jsonparse.map((jsondata) => {
            peaksInstance.segments.add({
              startTime: parseFloat(jsondata.startTime),
              endTime: parseFloat(jsondata.endTime),
              labelText: jsondata.segmentData,
              editable: false,
              speakerId: jsondata.speakerId,
              gender: jsondata.gender,
              agegroup: jsondata.agegroup,
              sentiment: jsondata.sentiment!=""?jsondata.sentiment:"NE",
              speakerDetails: jsondata.speakerDetails,
              isChecked: jsondata.isChecked
            });
            peaksInstance.points.add({
              time: parseFloat(jsondata.endTime),
              editable: false,
            });
          });
        } else {
          peaksInstance.segments.add({
            startTime: 0,
            endTime: isNaN(peaksInstance.player.getDuration()) ? datalist.fileDurationSecs : peaksInstance.player.getDuration(),
            labelText: "",
            editable: false,
            speakerId: "",
            sentiment:"NE",
          });
        }

        addnewSegmant(peaksInstance);

        peaksInstance.on("segments.click", (segment) => {
          // const point = peaksInstance.player.getCurrentTime()
          // // console.log("getCurrentSegment point", point)
          // // const seek = peaksInstance.player.seek(point)
          // //console.log("getCurrentSegment seek", seek)
          
          peaksInstance.segments._segments.map((seg) =>
            seg.update({ color: "rgba(0, 0, 0, 1)" })
          );
          let lastseg = peaksInstance.segments._segments.length;
          peaksInstance.segments._segments[parseInt(lastseg) - 1].update({
            color: "rgba(0, 0, 0, 1)",
          });
          segment.update({ color: "#4d4cac" });
          setMergeSegment([segment]);
        });

        //Change audio url code
          let fname=datalist.fileName;
          console.log("fname...",fname);
          if(fname)
          {
          let randomn=Math.random();
          let splitpath=fname.split(".");
          let newpath=splitpath[0]+randomn;
          
          let newfp=urlPath.replace(splitpath[0],newpath);
          console.log("new Path is..",newfp);
          setUrlPath(newfp);
          }


          const container = document.getElementById('zoomview-container');
          const view = peaksInstance.views.getView('zoomview');

          container.setAttribute('style', 'height: 130px', 'width: 800px');
          view.fitToContainer();
      });
    }
  }, [arrayAudioBuffer]);

  //Get Speaker data
  useEffect(() => {
    async function getSpeakerDetails() {
      const url = `${process.env.REACT_APP_URL}/transcription/editor/getspeakerdetails`;

      const headers = reqHeader(true, true, true);

      const params = {
        id,
      };

      const config = { headers, params };

      const { data } = await axios.get(url, config);
      setRowAdded(data.data.speakerDetails);

      console.log("data rowadded", data.data.speakerDetails)
    }
    getSpeakerDetails();
  }, [id]);

  ////Peak wave form add new segment on Enter key press
  useEffect(() => {
    
    if(arrayAudioBuffer)
    {
    const onKeyUp = ({key}) => {
      
        if (ALLOWED_KEYS.includes(key)) {
            setPressedKeys(previousPressedKeys => previousPressedKeys.filter(k => k !== key));
            
            if (key == "Enter") {
              console.log("Enter key pressed");
              console.log("allPeaks...",allPeaks);
              addnewSegmant(allPeaks);
            } 
        }

    }
    //document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
        //document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }
}, [allPeaks]);

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode == 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }
  },[])
  //Add New Segment
  const addnewSegmant = (peaksInstance) => {
    //
    var pk = { pt: peaksInstance };
    var wv = { nw: pk.pt };
    var pnts = wv.nw.points["_points"];
    var len = pnts.length;
    var lastPonint = len > 0 ? pnts[len - 1]["_time"] : 0;

    peaksInstance.points.add({
      time: peaksInstance.player.getCurrentTime(),
      editable: false,
    });

    let endtimearray = [];

    let curstarttime = peaksInstance.player.getCurrentTime();
    let lastsegstarttime = 0;
    let lastsegendtime = 0;

    let currentendtime = 0;
    let prevsegendtime = 0;

    //console.log("currentendtime", currentendtime);
    endtimearray = peaksInstance.segments._segments.map((et) => {
      //console.log("endtimevalues", et);
      if (et._startTime > lastsegstarttime) {
        lastsegstarttime = et._startTime;
      }
      if (et._endTime > lastsegendtime) {
        lastsegendtime = et._endTime;
      }

      if (et._endTime > curstarttime) return et._endTime;
    });

    debugger;
    if (
      peaksInstance.player.getCurrentTime() > 0 &&
      parseFloat(peaksInstance.player.getCurrentTime()).toFixed(2) !=
        parseFloat(lastPonint).toFixed(2)
    ) {
      if (peaksInstance.player.getCurrentTime() < lastsegendtime) {
        //console.log("Inside Loop");
        for (let i = 0; i < peaksInstance.segments._segments.length; i++) {
          //let maxlen = peaksInstance.segments._segments.length;
          //console.log("maxlen..", maxlen);
          if (peaksInstance.segments._segments[i]._endTime > curstarttime) {
            currentendtime = peaksInstance.segments._segments[i]._endTime;
            peaksInstance.segments._segments[i]._endTime =
              peaksInstance.player.getCurrentTime();
            //lastPonint = lastsegendtime;
            peaksInstance.segments.add({
              startTime: peaksInstance.player.getCurrentTime(),
              endTime: currentendtime,
              labelText: "",
              editable: false,
              speakerId: "",
              sentiment:"NE",
            });
            break;
          }
          if (peaksInstance.segments._segments[i]._endTime == curstarttime) {
            //alert("Please select Wave form to create a new segment.");
            break;
          }
        }
      } else {
        //debugger;
        lastPonint = lastsegendtime;
        //console.log("lastPonint..", lastPonint);
        if(lastPonint != peaksInstance.player.getCurrentTime()){
          peaksInstance.segments.add({
            startTime: lastPonint,
            endTime: peaksInstance.player.getCurrentTime(),
            labelText: "",
            editable: false,
            speakerId: "",
            sentiment:"NE",
          });
        }
      }
    }

    peaksInstance.segments._segments = peaksInstance.segments._segments.sort(
      (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
    );
    renderSegments(peaksInstance);
  };

  //Render Segment
  const renderSegments = (peaksInstance) => {
    peaksInstance.segments._segments = peaksInstance.segments._segments.sort(
      (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
    );
    peaksInstance.segments._segments.map((seg) =>
      seg.update({ color: "rgba(0, 0, 0, 1)" })
    );
    let lastseg = peaksInstance.segments._segments.length;
    if (lastseg != 0) {
      peaksInstance.segments._segments[parseInt(lastseg) - 1].update({
        color: "rgba(0, 0, 0, 1)",
      });
    }

    let allSegments = peaksInstance.segments.getSegments();
    setAddedSegments([...allSegments]);
  };


  //Update Segment
  const updateSegment = (item, id, e) => {
    const currentSegment = allPeaks.segments.getSegment(id);
    let objValue = Object.values(item);
    let objKey = Object.keys(item);
    objValue = objValue[0];
    objKey = objKey[0];
    if (
      objKey === "startTime" &&
      (objValue < 0 || objValue >= currentSegment.endTime || isNaN(objValue))
    ) {
      return null;
    } else if (
      objKey === "endTime" &&
      (objValue < 0 || objValue <= currentSegment.startTime || isNaN(objValue))
    ) {
      return null;
    }

    if (currentSegment && Object.values(item)) {
      currentSegment.update(item);
      let allSegments = allPeaks.segments.getSegments();
      setAddedSegments([...allSegments]);
    }
    //console.log(addedSegments);
  };

  //Loop Segment
  const getCurrentSegment = (e, id) => {

    let selectedSegment = allPeaks.segments.getSegment(id);
    let startTime = selectedSegment.startTime;
    allPeaks.player.seek(startTime)
    allPeaks.segments._segments.map((seg) =>
      seg.update({ color: "rgba(0, 0, 0, 1)" })
    );

    selectedSegment.update({ color: "#4d4cac" });

    setMergeSegment([selectedSegment]);
    //setSelectSegment([selectedSegment]);
  };

  //Merge Segment
  const getMergeSegment = (id) => {
    if (id == "peaks.segment.0") {
      alert("Segment 0 can not be merged.");
    } else if (id.length > 0 && id != null && id != undefined) {
      let mergeSegment = allPeaks.segments.getSegment(id);

      let curendtime = mergeSegment._endTime;
      let cursttime = mergeSegment._startTime;

      for (let i = 0; i < allPeaks.segments._segments.length; i++) {
        if (allPeaks.segments._segments[i]._endTime == curendtime) {
          let lbltxtcur = allPeaks.segments._segments[i]._labelText;
          let lbltxtprev = allPeaks.segments._segments[i - 1]._labelText;
          lbltxtprev = lbltxtprev + " " + lbltxtcur;

          allPeaks.segments._segments[i - 1]._endTime = curendtime;
          allPeaks.segments._segments[i - 1]._labelText = lbltxtprev;
          allPeaks.segments._segments.splice(i, 1);
          // allPeaks.segments.update({
          //   color: "rgba(94,129,244, 0.8)",
          // });
          //console.log("Segments after splicing..", allPeaks._segments);
          allPeaks.points.removeByTime(cursttime);
          setMergeSegment([]);
          break;
        }
      }

      allPeaks.segments._segments = allPeaks.segments._segments.sort(
        (a, b) => parseFloat(a._startTime) - parseFloat(b._startTime)
      );
      console.log("merged allpeaks before merged", allPeaks)
      renderSegments(allPeaks);
      console.log("merged allpeaks after merged", allPeaks)
      toast.success("Segment Merged successfully!");
    } else {
      //alert("Please select the segment to be merged.");
    }
  };

  //Handle Segment Error
  const handleSegmentErrors = (item, id, e, seconds, spkid) => {
    e.preventDefault();
    console.log("spkid seg error", spkid)
    let lan= datalist && datalist.srcLang;
      console.log("lan..",lan);
    let errors = validate(item.labelText, seconds, spkid, lan);
    errors.id = id;
    //console.log("validation Error", errors);
    setSegmentError(errors);
  };

  //save data to Json File
  const saveJson = async (e) => {
    e.preventDefault();
    //console.log("inside save json", allPeaks.segments._segments);
    console.log("datalist", datalist);
    //setFormErrors([]);
    let segmentarray = [];

    allPeaks.segments._segments.map((sdata, index) => {
      const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
      if(index == 0){
        let segmentdata = {
          id: sdata._id,
          startTime: sdata._startTime,
          segmentData: encodeURI((sdata._labelText).trim()),
          endTime: sdata._endTime,
          speakerId: sdata.speakerId,
          gender: sdata.gender?sdata.gender:"",
          agegroup: sdata.agegroup?sdata.agegroup:"",
          subsegments:sdata.SubSegments,
          sentiment:sdata.sentiment,
          isChecked:sdata.isChecked,
          speakerDetails:rowAdded
        };
        segmentarray.push(segmentdata);
      }else{
        let segmentdata = {
          id: sdata._id,
          startTime: sdata._startTime,
          segmentData: encodeURI((sdata._labelText).trim()),
          endTime: sdata._endTime,
          speakerId: sdata.speakerId,
          gender: sdata.gender?sdata.gender:"",
          agegroup: sdata.agegroup?sdata.agegroup:"",
          subsegments:sdata.SubSegments,
          sentiment:sdata.sentiment,
          isChecked:sdata.isChecked
        };
        segmentarray.push(segmentdata);
      }
   
    });
    
    const url = `${process.env.REACT_APP_URL}/transcription/editor/savejsondata`;

    const headers = reqHeader(true, true, true);
    console.log("header", headers);
    const body = {
      segmentarray,
      FileId: props.match.params.id,
      stage: datalist.stage,

    };
    console.log("body", body);
    const config = { headers };

    const { data } = await axios.put(url, body, config);

    if (data.success) {
      toast.success(data.msg);
    }
  };

  //Submit file
  const submitForm = async (e) => {
    e.preventDefault();
    saveJson(e);
   
    //console.log("inside Submit form");
    //setFormErrors([]);
    if(datalist.stage == "Transcription Assigned" || datalist.stage == "Transcription"){
      let segmentarray = [];
      //debugger;
      allPeaks.segments._segments.map((sdata, index) => {
        const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
        if(index == 0){
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked,
            speakerDetails:sdata.speakerDetails
            //speakerDetails:undefined
          };
          segmentarray.push(segmentdata);
        }else{
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked
          };
          segmentarray.push(segmentdata);
        }
       // console.log("segmentarray submit", segmentarray)
        let lan = datalist && datalist.srcLang;
        let segment = validate(sdata._labelText, TotalSeconds, sdata.speakerId, lan);
        let segmentErrorDetails = {
          id: sdata._id,
          segmenterrors: segment.segmentdata,
        };
        formErrors.push(segmentErrorDetails);
      });

      //console.log("formErrors", formErrors)
      setFormErrors(formErrors);
 
      //Set flag for show error in drawer list
      for (let i = 0; i < formErrors.length; i++) {
        //console.log("filter", formErrors[i].segmenterrors.length);
        if (formErrors[i].segmenterrors.length > 0) {
          setFlag(true);
          toast.error("Please resolve all segment errors before submit");
          setPane({ isPaneOpen: true })
          break;
        } else {
          setFlag(false);
        }

        if (i == formErrors.length - 1) {
          let childwindow = "";
          if(userType == "Manager"){
            if (!flag) {
              const result = await Confirm(
                "Make sure you have Saved your data before submitting. Once Submitted you will not be able to open the file again."
              );
              if (result) {
                //console.log("Inside submit flag false..");
              const url = `${process.env.REACT_APP_URL}/transcription/editor/submiteditorform`;
  
              const headers = reqHeader(true, true, true);
              debugger;
             console.log("segmentarray iside submitForm...", segmentarray);
              const body = {
                segmentarray,
                FileId: id,
                stage: datalist.stage,
              };
              //console.log("body", body);
              const config = { headers };
  
              const { data } = await axios.put(url, body, config);
              //console.log("Submitted data", data);
              if (data.success) {
                toast.success(data.msg);
                //history.push("/dashboard");
                //window.location.href("/dashboard")
                window.close();
              }
            }
          }
          }

            
      }
    }

     
    }else if(datalist.stage == "Proof Reading Assigned" || datalist.stage == "Proof Reading"){

      let segmentarray = [];
      //debugger;
      allPeaks.segments._segments.map((sdata, index) => {
        const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
        if(index == 0){
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked,
            speakerDetails:sdata.speakerDetails
            //speakerDetails:undefined
          };
          segmentarray.push(segmentdata);
        }else{
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked
          };
          segmentarray.push(segmentdata);
        }
       // console.log("segmentarray submit", segmentarray)
        let lan = datalist && datalist.srcLang;
        let segment = validate(sdata._labelText, TotalSeconds, sdata.speakerId, lan);
        let segmentErrorDetails = {
          id: sdata._id,
          segmenterrors: segment.segmentdata,
        };
        formErrors.push(segmentErrorDetails);
      });

      //console.log("formErrors", formErrors)
      setFormErrors(formErrors);
 
      //Set flag for show error in drawer list
      for (let i = 0; i < formErrors.length; i++) {
        //console.log("filter", formErrors[i].segmenterrors.length);
        if (formErrors[i].segmenterrors.length > 0) {
          setFlag(true);
          toast.error("Please resolve all segment errors before submit");
          setPane({ isPaneOpen: true })
          break;
        } else {
          setFlag(false);
        }

        if (i == formErrors.length - 1) {
          const result = await CustomDialog(
            <ProofReadSubmit allPeaks={allPeaks} datalist={datalist} id={id} />,
            { title: "Proofread Submit", showCloseIcon: true }
          );
            
      }
    }
  }
};

  //Submit file
  // const submitForm = async (e) => {
   
  //   e.preventDefault();
  //   saveJson(e);
   
  //   //console.log("inside Submit form");
  //   //setFormErrors([]);
  //   if(datalist.stage == "Transcription Assigned" || datalist.stage == "Transcription"){
  //     const result = await Confirm(
  //       "Make sure you have Saved your data before submitting. Once Submitted you will not be able to open the file again."
  //     );
  //     if (result) {
  //       let segmentarray = [];
  //       //debugger;
  //       //console.log("allPeaks submit", allPeaks.segments._segments)
  //       allPeaks.segments._segments.map((sdata, index) => {
  //         const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
  //         if(index == 0){
  //           let segmentdata = {
  //             id: sdata._id,
  //             startTime: sdata._startTime,
  //             segmentData: encodeURI((sdata._labelText).trim()),
  //             endTime: sdata._endTime,
  //             speakerId: sdata.speakerId,
  //             gender: sdata.gender?sdata.gender:"",
  //             agegroup: sdata.agegroup?sdata.agegroup:"",
  //             subsegments:sdata.SubSegments,
  //             sentiment:sdata.sentiment,
  //             isChecked:sdata.isChecked,
  //             speakerDetails:sdata.speakerDetails
  //             //speakerDetails:undefined
  //           };
  //           segmentarray.push(segmentdata);
  //         }else{
  //           let segmentdata = {
  //             id: sdata._id,
  //             startTime: sdata._startTime,
  //             segmentData: encodeURI((sdata._labelText).trim()),
  //             endTime: sdata._endTime,
  //             speakerId: sdata.speakerId,
  //             gender: sdata.gender?sdata.gender:"",
  //             agegroup: sdata.agegroup?sdata.agegroup:"",
  //             subsegments:sdata.SubSegments,
  //             sentiment:sdata.sentiment,
  //             isChecked:sdata.isChecked
  //           };
  //           segmentarray.push(segmentdata);
  //         }
  //        // console.log("segmentarray submit", segmentarray)
  //         let lan = datalist && datalist.srcLang;
  //         let segment = validate(sdata._labelText, TotalSeconds, sdata.speakerId, lan);
  //         let segmentErrorDetails = {
  //           id: sdata._id,
  //           segmenterrors: segment.segmentdata,
  //         };
  //         formErrors.push(segmentErrorDetails);
  //       });
  
  //       //console.log("formErrors", formErrors)
  //       setFormErrors(formErrors);
  
  //       // for(let i =0; i<allPeaks.segments._segments.length; i++ ){
  //       //   if(allPeaks.segments._segments[i].isChecked == false || allPeaks.segments._segments[i].isChecked == undefined){
  //       //     setFlag(true);
  //       //     toast.error("Please all segments should be proofread");
  //       //     break;
  //       //   }else{
  //       //     setFlag(false);
  //       //    }
  //       // }
  
  //       //Set flag for show error in drawer list
  //       for (let i = 0; i < formErrors.length; i++) {
  //         //console.log("filter", formErrors[i].segmenterrors.length);
  //         if (formErrors[i].segmenterrors.length > 0) {
  //           setFlag(true);
  //           toast.error("Please resolve all segment errors before submit");
  //           setPane({ isPaneOpen: true })
  //           break;
  //         } else {
  //           setFlag(false);
  //         }
  
  //         if (i == formErrors.length - 1) {
  //           let childwindow = "";
  //           if (!flag) {
  //             //console.log("Inside submit flag false..");
  //             const url = `${process.env.REACT_APP_URL}/transcription/editor/submiteditorform`;
  
  //             const headers = reqHeader(true, true, true);
  //             debugger;
  //            console.log("segmentarray iside submitForm...", segmentarray);
  //             const body = {
  //               segmentarray,
  //               FileId: id,
  //               stage: datalist.stage,
  //             };
  //             //console.log("body", body);
  //             const config = { headers };
  
  //             const { data } = await axios.put(url, body, config);
  //             //console.log("Submitted data", data);
  //             if (data.success) {
  //               toast.success(data.msg);
  //               //history.push("/dashboard");
  //               //window.location.href("/dashboard")
  //               window.close();
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }else if(datalist.stage == "Proof Reading Assigned" || datalist.stage == "Proof Reading"){
  //     const result = await CustomDialog(
  //       <ProofReadSubmit allPeaks={allPeaks} datalist={datalist} id={id} />,
  //       { title: "Proofread Submit", showCloseIcon: true }
  //     );
  //   }

  // };


  const handleKeyBoardEvents = (key, id, e) => {
    //
    e.preventDefault();
    //console.log("key, id", key, id);
    let selectedSegment = allPeaks.segments.getSegment(id);
    let Segment_EndTime = selectedSegment.endTime
   
    if (key == "ctrl+backspace") {
      let cursegid = mergeSegment.map((seg) => {
        return seg._id;
      });
      //console.log("cursegid", cursegid);
      getMergeSegment(cursegid);
    } else if (key == "Backspace" && e.ctrlKey) {
      let cursegid = mergeSegment.map((seg) => {
        return seg._id;
      });
      //console.log("cursegid", cursegid);
      getMergeSegment(cursegid);
    } else if (key == "enter") {
      addnewSegmant(allPeaks);
    } else if (key == "shift+tab") {
      allPeaks.player.playSegment(selectedSegment);
    } else if (key == "tab") {
      // debugger;
      if (isplay == false) {
        allPeaks.player.play();
        setIsPlay(true);
      } else if(isplay == true) {
        allPeaks.player.pause();
        setIsPlay(false);
      } 
    } else if(key == "down"){
      let getSegmentCurrentTime = allPeaks.player.getCurrentTime()
      //console.log("getSegmentCurrentTime", getSegmentCurrentTime - 0.2)
       getSegmentCurrentTime = getSegmentCurrentTime - 0.3
       return allPeaks.player.seek(getSegmentCurrentTime)
    } else if(key == "up"){
      let getSegmentCurrentTime = allPeaks.player.getCurrentTime()
      //console.log("getSegmentCurrentTime", getSegmentCurrentTime + 0.2)
       getSegmentCurrentTime = getSegmentCurrentTime + 0.3
       return allPeaks.player.seek(getSegmentCurrentTime) 
    } else if (key == "ctrl+s") {
      saveJson(e);
      //setPane({ isPaneOpen: true });
    }
    key = "";
  };

  //React-textarea-autocomplete code
  const Item = ({ entity: { name, string } }) => <div>{`${string}`}</div>;

  //Handle speaker selection on row
  const onChangehandleSpeaker = (id, speakerId) => {
    
    let result = rowAdded.find((obj) => {
      return obj.speakerid === speakerId;
    });
    console.log("result", result);
    const currentSegment = allPeaks.segments.getSegment(id);
    if (result === undefined && speakerId == "NS") {
      currentSegment &&
        currentSegment.update({
          speakerId: "NS",
          gender: "",
          agegroup: "",
        });
    } else if(result === undefined && speakerId == "OL"){
      currentSegment &&
      currentSegment.update({
        speakerId: "OL",
        gender: "",
        agegroup: "",
      });
    }else if(result === undefined && speakerId == "defaultvalue"){
      currentSegment &&
      currentSegment.update({
        speakerId: "",
        gender: "",
        agegroup: "",
      }) 
    }else {
      currentSegment &&
        currentSegment.update({
          speakerId: result.speakerid,
          gender: result.gender,
          agegroup: result.agegroup,
        });
    }

    let allSegments = allPeaks.segments.getSegments();
    console.log("allSegments change speaker id", allSegments);
    setAddedSegments([...allSegments]);
  };

  const getSpeakerDetails = async () => {
    const result = await CustomDialog(
      <AddSpeakerContent Fileid={props.match.params.id} lang={datalist.tgtLang} allPeaks={allPeaks}  stage={datalist.stage} />,
      { title: "Add Speaker", showCloseIcon: true }
    );
    if (result !== undefined) {
      setRowAdded(result.data);
    }
  };

  // const exportTotxtFile = () => {
  //   let textPrintArray = [];
  //   allPeaks.segments._segments.map((sdata) => {
  //     let segmentIdandData = {
  //       id: sdata._id,
  //       segmentData: sdata._labelText,
  //     };
  //     textPrintArray.push(segmentIdandData);
  //   });

  //   const element = document.createElement("a");
  //   let result = [];

  //   for (var x in textPrintArray) {
  //     if (textPrintArray.hasOwnProperty(x))
  //       result.push(
  //         textPrintArray[x].id.replace("peaks.segment.", "SegmentID:") +
  //           "\t" +
  //           textPrintArray[x].segmentData +
  //           "\n"
  //       );
  //   }
  //   const file = new Blob(result, { type: "text/plain;charset=utf-8" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "Export.txt";
  //   document.body.appendChild(element);
  //   element.click();
  // };

  //Spell checker service
  // const runSpeller = async (item, id, e) => {
  //   console.log("Inside runspeller", item, id);
  //   try{
  //     if (item) {
  //       //console.log("Inside submit flag false..");
  //       const corsanywhere = "https://corsanywhereapi-qsbsoa47pa-de.a.run.app/"
  //       const url = "http://spellcheck.eastus.cloudapp.azure.com/";
  //       const ApiUrl = corsanywhere + url
  //       //console.log("ApiUrl", ApiUrl)
  //       const headers = reqHeader(true, false, true);
  //       const body = {
  //         text: item.labelText
  //       };
  //       //console.log("body", body);
  //       const config = { headers };
  
  //       const { data } = await axios.post(ApiUrl, body, config);
  //       console.log("Submitted data", data);
  //       if (data) {
  //         let worlist = JSON.parse((data.Data).toString())
  //       //   let seenWords = {};
  //       //   worlist = worlist.filter(function(currentObject) {
  //       //     if (currentObject.word in seenWords) {
  //       //         return false;
  //       //     } else {
  //       //       seenWords[currentObject.word] = true;
  //       //         return true;
  //       //     } 
  //       // });
  //       //console.log("wordlist", worlist);
  //       let spellData = []
  //       worlist.map((getWord)=>{
  //         //let errors = []
  //         let speller = {
  //           id: id,
  //           getword: getWord.word
  //         }
  //         spellData.push(speller)
  //         //console.log("spellData", spellData)
  //       })
  //       setSpellText(spellData);
  //       //setNotify("")
  //       }
  //     }
  //   }catch(err){
  //     console.log("err", err)
  //     // toast.warning("Warning: Please ask admin to reset the speller!",{
  //     //   draggablePercent: 60
  //     // });
  //     // let msg = "Please ask admin to reset the speller!"
  //    // console.log("msg", msg)
  //     // setNotify(msg)
  //   }


  // }

 //Proofread service
  const handleOnChange = (e) => {
    const {name, checked} = e.target;
    console.log("name, checked", name, checked)
    const currentSegment = allPeaks.segments.getSegment(name);
    //console.log("currentSegment", currentSegment.id)
    if(currentSegment.id === name){
      currentSegment.update({ isChecked: checked })
      let allSegments = allPeaks.segments.getSegments();
      console.log("allSegments", allSegments);
      setAddedSegments([...allSegments]);
    }
  };

  //Segment Content in textarea
  const SegmentContent = addedSegments.map((segments, index) => {
    //console.log("segments contents", segments)
    const speakerIDs = rowAdded.map((item) => {
      return {
        name: `OVERLAP ${item.speakerid}`,
        string: `<OVERLAP ${item.speakerid}></OVERLAP>`,
      };
    });

    const newAngularTags = [...angularTags, ...speakerIDs];

    // const startTimeFormatted = segments.startTime;
    // const endTimeFormatted = segments.endTime;
    const startTimeFormatted = new Date(segments.startTime * 1000)
    .toISOString()
    .substr(11, 11);

    const endTimeFormatted = new Date(segments.endTime * 1000)
    .toISOString()
    .substr(11, 11);

    const TotalSeconds = (segments.endTime - segments.startTime).toFixed(3);
    let segmentIndex = addedSegments.findIndex((element, index) => {
      if (element._id === segments._id) {
        return true;
      }
    });
    
    return (
      <div key={index+1}>

        <KeyboardEventHandler
          handleKeys={["ctrl+backspace", "enter", "ctrl+s", "shift+tab", "tab", "up", "down"]}
          //handleEventType={"keyup"}
          //handleFocusableElements
          onKeyEvent={(key, e) => handleKeyBoardEvents(key, segments.id, e)}>
          <div className="segment-details">
            <div
              className={
                "seg-info d-flex" +
                " " +
                (segmentError.segmentdata && segmentError.id == segments.id
                  ? "errorWrap"
                  : "")
              }
              key={segments.id}>
              <select
                className="speaker-selection"
                key={segments.id}
                value={segments.speakerId}
                onChange={(e) =>
                  onChangehandleSpeaker(segments.id, e.target.value)
                }>
                <option value="defaultvalue">Select Speaker</option>
                <option value="NS">NO SPEAKER</option>
                <option value="OL">OVERLAP</option>
                {rowAdded.map((item) => {
                  //console.log("speaker item", item);
                  return (
                    <option key={item.speakerid} value={item.speakerid}>
                      {item.speakerid}
                    </option>
                  );
                })}
              </select>
              <div>{`Segment ${segmentIndex + 1}`}</div>
              <div>
                {startTimeFormatted} - {endTimeFormatted}
              </div>
              <div>{TotalSeconds}</div>
              <div className="sentiment-section d-flex">
                <div className="status-container">
                  {segmentError.segmentdata != "" &&
                    segmentError.id == segments.id && <ErrorStatus />}
                  <div className="error-popup">
                    <div className="error-item">
                      {segmentError.segmentdata &&
                        segmentError.segmentdata.map((error, index) => {
                          if (segmentError.id == segments.id) {
                            return (
                              <strong key={index+1}>
                                {error.SegmentDataEmpty}
                                {error.TwentySecondError}
                                {/* {error.FourSecondStandaloneTags} */}
                                {error.numericValue}
                                {error.SpecialCharacters}
                                {error.MathSymbols}
                                {error.Okaystring}
                                {error.openCloseMismatch}
                                {error.EmptyAngularTags}
                                {error.AngularMismatch}
                                {error.InvalidAngularTag}
                                {error.InvalidFILTag}
                                {error.InvalidSILTag}
                                {error.InvalidUNINTag}
                                {error.SentimentEmpty}
                                {error.speakerIdEmpty}
                                {error.genderEmpty}
                                {error.agegroupEmpty}
                                {error.InvalidLMTag}
                                {error.SpaceBeforePeriod}
                                {error.Invalidoverlaptag}
                              </strong>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
                  {datalist.stage == "Proof Reading Assigned" || datalist.stage == "Proof Reading" ?
                  <div>
                    <input
                      type="checkbox"
                      id={segments.id}
                      name={segments.id}
                      checked={segments?.isChecked || false}
                      onChange={handleOnChange}
                    />
                  </div>:""}
              </div>
            </div>
            <div className="seg-summary d-flex">
              <ReactTextareaAutocomplete
                className="segmentTextarea"
                //className={datalist.tgtLang == "Urdu" ? "segmentTextarea segmentTextareaRtl" : "segmentTextarea"}
                key={segments.id}
                //charset="ISO-8859-1"
                value={segments.labelText}
                onChange={(e) =>
                  updateSegment({ labelText: e.target.value }, segments.id)
                }
                onClick={(e) => getCurrentSegment(e, segments.id)}
                onBlur={(e) => {
                  handleSegmentErrors(
                    { labelText: e.target.value },
                    segments.id,
                    e,
                    TotalSeconds,
                    segments.speakerId
                  );
                  // runSpeller(
                  //   { labelText: e.target.value },
                  //   segments.id,
                  //   e
                  // ) 
                } 
                }
              
                loadingComponent={() => <span>Loading</span>}
                minChar={0}
                trigger={{
                  "<": {
                    dataProvider: (token) => {
                    // console.log("token is ", token);
                    
                     return newAngularTags.slice(0, newAngularTags.length).filter((word) =>
                        word.string.toUpperCase().
                        includes(token.toUpperCase())
                      );
                    },
                    component: Item,
                    output: (item, trigger) => item.string,
                  },
                  "\\": {
                    dataProvider: (token) => {
                      return slashTags.slice(0, slashTags.length).filter((word) =>
                      word.string.toUpperCase().includes(token.toUpperCase())
                    );
                    },
                    component: Item,
                    output: (item, trigger) => item.string,
                  },
                }}
              />
            </div>
              {spelltext.map((spellText, index)=>{
                if(spellText.id == segments.id){
                  return<span key={index+1} style={{backgroundColor:"yellow"}}>{spellText.getword}{index+1 ? ", ": ""}</span>
                }
              })}
             
          </div>
        </KeyboardEventHandler>
      </div>
    );
  });

  const SlidePane = (
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      isOpen={pane.isPaneOpen}
      width="400px"
      title="Errors"
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setPane({ isPaneOpen: false });
        setFormErrors([]);
        setFlag();
      }}>
      <div className="errortitle">
        <ErrorIcon /> Please solve the following errors.
      </div>

      {flag == true ? (
        formErrors &&
        formErrors.map((errors) => {
          // console.log("flag", flag)
          let splitSegmentId = errors.id.split(".");
          let getSegmentId = splitSegmentId[2];
          let segmentIndex = addedSegments.findIndex((element, index) => {
           // console.log("element._id, errors.id", errors.id, getSegmentId)
            if (element._id === errors.id) {
              return true;
            }
          });
          //console.log("segmentIndex", segmentIndex)
          return errors.segmenterrors.map((error, index) => {
            return (
              <div
                className="errorItem"
                key={`Segment ${parseInt(getSegmentId) + 1}_${index + 1} `}>
                <div className="twoWayArrow"></div>
                {`Segment ${segmentIndex + 1} `}
                {error.SegmentDataEmpty}
                {error.TwentySecondError}
                {/* {error.FourSecondStandaloneTags} */}
                {error.numericValue}
                {error.SpecialCharacters}
                {error.MathSymbols}
                {error.Okaystring}
                {error.openCloseMismatch}
                {error.EmptyAngularTags}
                {error.AngularMismatch}
                {error.InvalidAngularTag}
                {error.InvalidFILTag}
                {error.InvalidSILTag}
                {error.InvalidUNINTag}
                {error.SentimentEmpty}
                {error.speakerIdEmpty}
                {error.genderEmpty}
                {error.agegroupEmpty}
                {error.InvalidLMTag}
                {error.SpaceBeforePeriod}
                {error.Invalidoverlaptag}
              </div>
            );
          });
        })
      ) : (
        <div>Thanks for saving. You have no validation errors.</div>
      )}
    </SlidingPane>
  );

  return !arrayAudioBuffer ? (<Spinner />) : (
    <>
      {/* <RightClickMenu /> */}
      <div className="container">
        {/* <ToastContainer position="top-left" /> */}
        <div className="transcriber-container">
          <div className="transcriber-header d-flex">
            <div className="logo">
              <img src={Logo} alt="Logo" className="" height="40" />
            </div>
            <UserProfile />
          </div>
          <div className="trancriber-detail d-flex">
            <ul className="transcriber-info">
              <li>
                <label>File Name</label>
                <div>{datalist.fileName}</div>
              </li>
              <li>
                <label>Transcription Time</label>
                <div>{datalist.fileDuration}</div>
              </li>
              <li>
                <label>Language</label>
                <div>{datalist.tgtLang}</div>
              </li>

              <li>
                <label>ETA</label>
                <div>
                  {datalist.ETA && format(new Date(datalist.ETA), "dd/MM/yyyy")}
                </div>
              </li>
            </ul>

            <div>
              <button
                onClick={async () => {
                  await CustomDialog(<GuidelinesContent />, {
                    title: "Megdap Guidelines",
                    showCloseIcon: true,
                  });
                }}
                className="btn guideline">
                Megdap Guidelines
              </button>
              <button
                onClick={async () => {
                  await CustomDialog(<KeyShortcutContents />, {
                    title: "Keyboard Shortcuts",
                    showCloseIcon: true,
                  });
                }}
                className="btn shortcut">
                Keyboard Shortcuts
              </button>
            </div>
          </div>
          <div id="stickywave" className="transcriber-waves">
            <div className="transWaveWrap">
              <div id="peaks-container">
                <div id="zoomview-container"></div>
                {/* <div id="overview-container"></div> */}
              </div>
            </div>
            <div className="audioWrap">
            <ToastContainer position="top-left" />
              {urlPath && (
                <audio
                  className="transAudioWrap"
                  id="audio"
                  controls
                  controlsList="nodownload">
                  <source src={urlPath} type="audio/wav" />
                </audio>
              )}
            </div>
            {/* <div> {notify} </div> */}
          </div>
        </div>
        <div id="segmentwrap" className="segment-container">
          <div className="tableWrap projectTableWrap block">
            <div className="segment-header d-flex">
              <div>Title</div>
              <div className="action-btn-group d-flex">
                {/*Add Speaker Button*/}
                <button onClick={getSpeakerDetails}>Add Speaker</button>
                {/*Add New Segment Button*/}
                <button onClick={(e) => addnewSegmant(allPeaks)}>
                  Add Segment
                </button>
                {/*Merge Segment Button*/}
                {!mergeSegment.length || addedSegments.length <= 1 ? (
                  <button
                    disabled={
                      !mergeSegment.length || addedSegments.length <= 1
                    }>
                    Merge Segment
                  </button>
                ) : (
                  mergeSegment.map((mergeSegment) => {
                    return (
                      <button
                        key={mergeSegment.id}
                        onClick={(e) => getMergeSegment(mergeSegment.id)}>
                        Merge Segment
                      </button>
                    );
                  })
                )}
                {/*Save Button*/}
                <button
                  onMouseDown={(e) => {
                    saveJson(e);
                    // setPane({ isPaneOpen: true });
                  }}>
                  Save
                </button>
                {SlidePane}
                {/*Submit button*/}
                <button
                  onMouseDown={(e) => {
                    submitForm(e);
                    //setPane({ isPaneOpen: true });
                  }}>
                  Submit
                </button>
                {/*Export Text Button*/}
                {/* <button onClick={(e) => exportTotxtFile(allPeaks)}>
                  Export Text
                </button> */}
                {/* <button
                onClick={async () => {
                  await CustomDialog(<FindAndReplace allPeaks={allPeaks} />, {
                    title: "Find and Replace",
                    showCloseIcon: true,
                  });
                }}
                >
                Find and Replace
              </button> */}
                {/* <button onClick={(e) => runSpeller(allPeaks)}>
                  Run Speller
                </button> */}
                {/* <button onClick={(e) => AddStartTime(allPeaks)}>
                  Add start time
                </button> */}
              </div>
            </div>
            <div className="segment-data-wrap">
              {SegmentContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MsEditor;

import React, { useEffect, useState } from "react";

const KeyShortcutContents = () => {
  return(
    <div className="popupInner">
        <div className="popupContent keyboard-shortcuts">
            <ul className="shortcut-list">
              {/* <li className="shortcut-item">
                <div className="twoWayArrow"></div>
                <div className="label">
                  Play Segment: TAB
                </div>
              </li> */}

              <li className="shortcut-item">
                <div className="twoWayArrow"></div>
                <div className="label">
                  Play Segment Repeat: TAB
                </div>
              </li>

              {/* <li className="shortcut-item">
                <div className="twoWayArrow"></div>
                <div className="label">
                  Add Segment: Enter
                </div>
              </li> */}

              {/* <li className="shortcut-item">
                <div className="twoWayArrow"></div>
                <div className="label">
                  Merge Segment: CTRL + BACKSPACE
                </div>
              </li> */}

              <li className="shortcut-item">
                <div className="twoWayArrow"></div>
                <div className="label">
                 Save Segment: CTRL + S
                </div>
              </li>
            </ul>
        </div>
    </div>
  )
}

export default KeyShortcutContents;
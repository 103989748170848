export default function validate(
    value,
    segduration,
    timestamp
  ) {
    let errors = [];
    let segmentdata = [];
      debugger; 
    //check Blank segment
    if (!value || value == "" || value == " ") {
      let SegmentDataEmpty = "Segment data is empty";
      segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
    }
    //more than 20 seconds check on segment length
    //debugger;
    if (timestamp>0 && segduration >= timestamp) {
      let TwentySecondError =
        `Segment is of more than ${timestamp} seconds duration, please split into smaller segments`;
      segmentdata.push({ TwentySecondError: TwentySecondError });
    }
    if(timestamp==0 && segduration >= 20)
    {
      let TwentySecondError =
        `Segment is of more than 20 seconds duration, please split into smaller segments`;
      segmentdata.push({ TwentySecondError: TwentySecondError });
    }
    
    ////Special characters check
    let specialcharactersregex = /[\@#$%^&*()<>":{}\/`~;_\[\]]/g
    let SpecialCharacters = specialcharactersregex.test(value);
  
    if (SpecialCharacters) {
      if (!value.match(/([\,.'!?\-]+[\\]+\S*)/)) {
        SpecialCharacters = "Special characters found.";
        segmentdata.push({ SpecialCharacters: SpecialCharacters });
      }
    }
  
    errors.segmentdata = segmentdata;
    return errors;
  }
  
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Split from "react-split";
import LeftNavigation from "./left-navigation";
import {
  SearchIcon,
  TranscriptionOngoing,
  TranscriptionCompleted,
  TranscriptionPending,
} from "./icons.component";
import UserProfile from "./userProfile";
import TableList from "./Table/TableList";
import Tile from "./Tile";
import DataReactTable from "./Table/DataReactTable.jsx";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";
import CustProjectList from "./CustProjectList.jsx";
import LangDomainList from "./LangDomainList.jsx";
import FileList from "./FileList.jsx";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const AdmPRDashboard = () => {
  const userLogin = useSelector((state) => state.userLogin);

  console.log("userInfo in admprdashboard is ", userLogin);

  const { fullName, vendType,userType,role } = userLogin.user;
  console.log("role..",role);

  const { ipaddr } = userLogin;

  const [supervisorData, setSupervisorData] = useState([]);

  const [customer, setCustomer] = useState({});
  const [clickedLangDomain, setClickedLangDomain] = useState({});

  useEffect(() => {
    setClickedLangDomain({});
  }, [customer]);

  const [reload, setReload] = useState(false);

  const reloaded = (data) => {
    console.log("reloaded is done", data);
    if (data !== reload) {
      setReload(data);
    }
  };

  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);

  // const [ipaddress, setIpAddress] = useState("");

  // useEffect(() => {
  //   async function getIpAddress() {
  //     const loc = await axios.get("https://ipapi.co/json/");
  //     console.log("loc is ", loc);

  //     setIpAddress(loc.data.ip);
  //   }

  //   if (ipaddress === "") {
  //     getIpAddress();
  //   }
  // }, []);

  // console.log("IP Address is ", ipaddress);

  useEffect(() => {
    async function getData() {
      const url = `${process.env.REACT_APP_URL}/transcription/transcription/customerprojectlist`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          console.log("AdmPR data is ", data);

          let fileList = [];

          // data.map((file) => {
          data.forEach((file, index) => {
            let { tgtLang: language, domains, subRows } = file;

            // let abcd = domains.map((value) => {
            //   return `${value.language} - ${value.name} - ${value.frequency} `;
            // });

            let uniqueDomains = [
              ...new Set(
                domains.map((value) => {
                  return `${value.language} - ${value.name} - ${value.frequency} `;
                })
              ),
            ];

            let langDetails = uniqueDomains.map((value, index) => {
              const lang = value.split("-")[0].trim();
              const domain = value.split("-")[1].trim();
              const frequency = value.split("-")[2].trim();

              let folderPath = [
                ...new Set(
                  subRows
                    .filter((val, index) => {
                      return (
                        val.tgtLang === lang &&
                        val.domain === domain &&
                        val.frequency === frequency
                      );
                    })
                    .map((val) => {
                      return val.folderPath;
                    })
                ),
              ];
              // console.log("folder Path is ", folderPath);

              return {
                id: index + 1,
                name: lang,
                domain: domain,
                frequency: frequency,
                section: value,
                folderPath: folderPath.toString(),
              };
            });

            // let langDetails = language.split(",").map((value, index) => {
            //   return {
            //     id: index + 1,
            //     name: value,
            //   };
            // });

            let project = {
              id: file._id,
              customerID: file.customerID,
              custName: file.custName,
              custEmail: file.custEmail,
              projectName: file.projectName,
              ETA: file.ETA,
              stage: file.stage,
              language: language,
              subRows: langDetails,
              subRowDetails: file.subRows,
              uniqueDomains: uniqueDomains,
            };
            fileList = [...fileList, project];
          });

          setSupervisorData(fileList);

          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }

    if (role === "AdmPR") {
      getData();
    }
  }, [role]);
  
  //console.log("supervisorData is ", supervisorData);
  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">
              Welcome,<strong> &nbsp; {fullName}</strong>
            </div>

            <UserProfile />
          </div>

          <div className="projectListWrap">
            {role !== "admPR" ? (
              ""
            ) : (
              <ul className="projectList d-flex">

                <Tile
                  title="NEW JOBS"
                  titleType="TranscriptionPending"
                  theme="projectBlock pendingProj"
                  url="/onassignfilelist"
                />

                <Tile
                  title="ONGOING JOBS"
                  titleType="TranscriptionOngoing"
                  theme="projectBlock onGoingProj"
                  url="/inprogressfilelist"
                />

                <Tile
                  title="TOTAL EARNINGS"
                  titleType="TotalEarnings"
                  theme="projectBlock readyToDeliver"
                  url="/totalearnings"
                />
              </ul>
            )}
          </div>
          <div className="tableWrap projectTableWrap block">
          {/* {role === "AdmPR" ? ( */}
          
         {/* <DataReactTable src={supervisorData} /> */}
            <Split className="split" sizes={[50, 50]}>
            <CustProjectList
              serviceType="Transcription"
              type="assign"
              totalDuration={true}
              uploadedDuration={true}
              handledBy={true}
              status={false}
              progress={false}
              setCustomer={setCustomer}
            />
            <LangDomainList
              serviceType="Transcription"
              type="assign"
              totalDuration={true}
              langDomainDuration={true}
              status={false}
              progress={false}
              customer={customer}
              setClickedLangDomain={setClickedLangDomain}
            />
          </Split>
          
          <div>
            <FileList
              serviceType="Transcription"
              type="assign"
              multiSelect={true}
              customer={customer}
              progress={false}
              preview={true}
              clickedLangDomain={clickedLangDomain}
            />
          </div>
          {/* ) : (
            ""
          )} */}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AdmPRDashboard;

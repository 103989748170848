import React from "react";
import DocViewer, { DocViewerRenderers }  from "react-doc-viewer";

const GuidelinesContent = () => {

  const docs = [
    { uri: "https://storage.googleapis.com/transcriberprojects/MegdapGuidelines/General%20Guidelines.docx" }
  ];

  return(
    <div className="popupInner">
    <div className="popupContent megdap-guidelines">
    <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        config={{
          header: {
            disableHeader: false,
            disableFileName: false,
            retainURLParams: false
          }
        }}
        style={{ height: 500 }}
      />
    </div>
    <div className="popupContent megdap-guidelines">
      <a href="https://www.youtube.com/watch?v=0qN8MUygL3w" target="_blank" style={{fontSize:"medium"}}>Traning Video</a>
    </div>
    </div>
  )
}

export default GuidelinesContent;
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const CHANGEPASSWORD_REQUEST = "CHANGEPASSWORD_REQUEST";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAIL = "CHANGEPASSWORD_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const FORGOTPASSWORD_EMAIL_SUCCESS = "FORGOTPASSWORD_EMAIL_SUCCESS";
export const FORGOTPASSWORD_EMAIL_FAIL = "FORGOTPASSWORD_EMAIL_FAIL";

export const RESETPASSWORD_EMAIL_SUCCESS = "RESETPASSWORD_EMAIL_SUCCESS";
export const RESETPASSWORD_EMAIL_FAIL = "RESETPASSWORD_EMAIL_FAIL";

export const USER_MSG_UPDATE_REQUEST = "USER_MSG_UPDATE_REQUEST";
export const USER_MSG_UPDATE_SUCCESS = "USER_MSG_UPDATE_SUCCESS";
export const USER_MSG_UPDATE_FAIL = "USER_MSG_UPDATE_FAIL";

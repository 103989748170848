export default function validate(
  value
) {
  let errors = [];
  let segmentdata = [];

  //debugger;
  //check Blank segment
  //console.log("segmentIstranscribed", value, segduration,spkid, segmentIstranscribe)
  if(!value || value == "" || value == " "){
      let SegmentDataEmpty = "Segment data is empty";
      segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
  }

  //more than 20 seconds check on segment length
  //debugger;
  // if (segduration >= 20) {
  //   let TwentySecondError =
  //     "Segment is of more than twenty seconds duration, please split into smaller segments";
  //   segmentdata.push({ TwentySecondError: TwentySecondError });
  // }

  //Check Space before period
  let spaceperiodregex = /\s[.|?|!]/;
  let SpaceBeforePeriod = spaceperiodregex.test(value);
  if (SpaceBeforePeriod) {
    SpaceBeforePeriod = "Space found before punctuation mark";
    segmentdata.push({ SpaceBeforePeriod: SpaceBeforePeriod });
  }


  //Numerice value in text-area check
 // debugger;
  // let numberregex = /[०-९]|[0-9]|[০-৯]|[૦-૯]|[೦-೯]|[൦-൯]|[୦-୯]|[੦-੯]|[௦-௯]|[౦-౯]|[۰-۹]/g
  // //let numberregex = /\d/;
  // let Overlapregex=/<OVERLAP\s*\S\d*>/g;
  // let tempval=value;
  // //console.log("tempval..",tempval);
  // tempval=tempval.replace(Overlapregex,"");
  // let numericValue = numberregex.test(tempval);
  
  // if (numericValue) {
  //   //let Overlap=/\W\d/;
  //   numericValue = "Number in digits found";
  //   segmentdata.push({ numericValue: numericValue });
  // }

  ////Special characters check
  //.!?,;:
  //debugger;
  let specialcharactersregex = /[\@#$%^&*:()"\/`~;\[\]]/g
  let SpecialCharacters = specialcharactersregex.test(value);

  if (SpecialCharacters) {
    if (!value.match(/([\,.'!?|{}\-]+[\\]+\S*)/)) {
      SpecialCharacters = "Special characters found.";
      segmentdata.push({ SpecialCharacters: SpecialCharacters });
    }
  }

  ////Math symbols check
  //.!?,;:
  //debugger;
  //
  let mathsymbolssregex = /[\+\=]/g;
  let MathSymbols = mathsymbolssregex.test(value);

  if (MathSymbols) {
    MathSymbols = "Math symbols found.";
    segmentdata.push({ MathSymbols: MathSymbols });
  }

  errors.segmentdata = segmentdata;
  return errors;
}

import React from "react";
import { AvatarChangeIcon } from "./icons.component";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import reqHeader from "../helper/reqHeader";
import UserAvatar from 'react-user-avatar';
 

const schema = yup.object().shape({
  fname: yup
  .string()
  .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
  .required("First name is required!"),
lname: yup
  .string()
  .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!")
  .required("Last name is required!"),
email: yup
  .string(),
skype: yup
  .string(),
mobile: yup
  .string()
  .required("Mobile Number is required!")

});



const MyProfile = () => {

  const user = useSelector((state) => state.userLogin.user); 
  const {fname,email,mobile,lname,skypeID} = user;
  

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues:{
      fname: fname,
      email:email,
      mobile:mobile,
      lname:lname,
      skype:skypeID
      
    }
  });


const { register, handleSubmit, errors, setValue } = methods;



const submitHandler = async(data) => {
  const {fname,lname,mobile,skype}= data;

  const url = `${process.env.REACT_APP_URL}/transcription/usersProfile/`;

  const headers = reqHeader(true, true, true);

  const config = {
    headers,
  };
  
  const body = {
    mobile: mobile,
    fname: fname,
    lname: lname,
    skypeID: skype,
  };

  try {
    const response = await axios.put(url, body, config);
    const result = await response.data;
    toast.success("Profile Updated !!")
    console.log("The result " + JSON.stringify(result));
  } catch (error) {
    console.log(error);
    toast.error("Opps! something went wrong")
  }


};

  return (
    <>
      <div className="container completeJobPage d-flex">
        <LeftNavigation />
        <ToastContainer/>
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">
              <span>Welcome,</span><span className="UName"> Admin</span>
            </div>

            <UserProfile />
          </div>

          <div className="myProfile-Content">
            <div className="myProfile-content-head">
              <h3 className="myProfile-title">My Profile</h3>

              <div className="myProfile-avatar">
                <UserAvatar name={user.fullName} size="150" round="50%" color="#0AC6E8" style={{fontSize:"40px"}} textSizeRatio={2} />
              </div>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
            <div className="myProfile-form">
              <div className="d-flex myProfile-formRow">
                <div className={`inputWrap ${errors.fname ? "error" : ""}`}>
                  <input type="text" placeholder="First Name" name="fname"  ref={register}  />
                  {errors.fname && (
                      <span className="inputErrorMsg">
                        {errors.fname?.message}
                      </span>
                    )}
                </div>

                <div className={`inputWrap ${errors.lname ? "error" : ""}`}>
                  <input type="text" placeholder="Last Name" name="lname"  ref={register}  />
                  {errors.lname && (
                      <span className="inputErrorMsg">
                        {errors.lname?.message}
                      </span>
                    )}
                </div>

                <div className="inputWrap">
                  <input type="text" placeholder="Email ID" name="email"   ref={register} disabled />
                </div>

                <div className="inputWrap">
                  <input type="text" placeholder="Skype ID" name="skype"  ref={register} />
                </div>

                <div className={`inputWrap ${errors.mobile ? "error" : ""}`}>
                  <input type="text" placeholder="Mobile" name="mobile"  ref={register}  />
                  {errors.mobile && (
                      <span className="inputErrorMsg">
                        {errors.mobile?.message}
                      </span>
                    )}
                </div>
                {/* <div className="inputWrap">
                </div> */}
              </div>
            </div>
            <div className="myProfile-action">
              <button  type="submit" className="profile-update-btn">update</button>
            </div>
            </form>
          </div>
          

          
        </div>
      </div>
    </>
  );
};

export default MyProfile;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { forgotpassword, clearMessage } from "../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Logo from "../assets/images/Logo.svg";
import { PasswordHide, PasswordShow } from "./icons.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tr } from "date-fns/locale";
import axios from "axios";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!")
});



const Forgotpassword = () => {

  const { register, handleSubmit, watch, errors, clearErrors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  let { loading, authenticated, error, msg } = userLogin;
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading && msg != "") {
      toast.success(msg);
      dispatch(clearMessage(userLogin));
    } else if (error != "") {
      toast.error(error);
      dispatch(clearMessage(userLogin));
    }
  }, [error, msg])

  const submitHandler = (data) => {
    console.log("Forgotpassword Submit Handler is ", data);
    dispatch(forgotpassword(data));
    
  };

  return (
    <>
      <div className="container RegisterWrap d-flex">
         <ToastContainer />
        <div className="registerLHS">
          <div className="logoSlogan-container">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="slogan">Transcriber</div>
          </div>
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }} 
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
          <h2 className="loginTitle"><strong>Forgot Password</strong></h2>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">
                
                <div
                  className={`inputWrap emailField ${
                    errors.email ? "error" : ""
                  }`}>
                  <input
                    ref={register}
                    type="text"
                    placeholder="Email ID"
                    name="email"
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div className="formFooter">
                  <button>Submit</button>
                  {/* <div className="loginLink">
                    Not a Member? <Link to="/transcriberregister">Register Now</Link>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import DataTable, { defaultThemes } from "react-data-table-component";
import FilterComponent from "./Table/DataTableFilterComponent.jsx";
import { Link } from "react-router-dom";

toast.configure();

const ErrorList = () => {
  const location = useLocation();

  console.log("State is ", location.state);

  const errorList =
    location.state !== undefined ? location.state.errorList : "";

  console.log("errorList is ", errorList);

  const [dataList, setDataList] = useState(errorList);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const history = useHistory();

  const cols = [
    {
      name: "ID",
      // selector: "id",
      selector: (row) => row.id,
      sortable: true,
      omit: true,
    },
    {
      name: "File Name",
      // selector: "title",
      selector: (row) => row.fileName,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 700 }}>{row.fileName}</div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const filteredItems = errorList.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );

    setDataList(filteredItems);
  }, [errorList, filterText]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const ExpandableComponent = ({ data: rowData }) => {
    // <img src={data.image} alt={data.title} />

    console.log("ExpandableComponent datas rowData is ", rowData);

    const { subRows: rows } = rowData;

    const [errors, setErrors] = useState(rows);

    const cols = [
      {
        name: "ID",
        // selector: "id",
        selector: (row) => row.id,
        sortable: true,
        omit: true,
      },
      {
        name: "Name",
        // selector: "title",
        selector: (row) => row.fileName,
        sortable: true,
        wrap: true,
        omit: true,
      },
      {
        name: "Segment",
        // selector: "title",
        selector: (row) => row.segment,
        sortable: true,
        wrap: true,
      },
      {
        name: "Error Type",
        // selector: "title",
        selector: (row) => row.errorType,
        sortable: true,
        wrap: true,
      },
      {
        name: "Message",
        // selector: "title",
        selector: (row) => row.message,
        sortable: true,
        wrap: true,
      },
    ];

    const handleRowClicked = (row) => {
      console.log("ExpandableComponent handleRowClicked is ", row);

      const updatedData = rows.map((item) => {
        if (row.id !== item.id) {
          return item;
        }

        return {
          ...item,
          toggleSelected: !item.toggleSelected,
        };
      });

      setErrors(updatedData);
    };

    const conditionalRowStyles = [
      {
        when: (row) => row.toggleSelected,
        style: {
          backgroundColor: "#FF8C69",
          userSelect: "none",
        },
      },
    ];

    const customStyles = {
      header: {
        style: {
          minHeight: "56px",
        },
      },
      headRow: {
        style: {
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          borderTopColor: defaultThemes.default.divider.default,
        },
      },
      headCells: {
        style: {
          "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
          },
        },
      },
      cells: {
        style: {
          "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
          },
        },
      },
    };

    return (
      <>
        <DataTable
          title=""
          columns={cols}
          data={errors}
          keyField={"id"}
          defaultSortFieldId={2}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          selectableRows={false}
          onRowClicked={handleRowClicked}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader={false}
          subHeader={false}
          customStyles={customStyles}
        />
      </>
    );
  };

  const handleRowClicked = (row) => {
    console.log("ErrorList handleRowClicked is ", row);

    const { _id: id } = row.fileDetails.length === 1 ? row.fileDetails[0] : "";

    console.log("ErrorList handleRowClicked fileID is ", id);

    if (id !== "") {
      // history.push({
      //   pathname: `/ms_editor/${id}`,
      // });

      const win = window.open(`/ms_editor/${id}`, "_blank");
      win.focus();

      // <Link
      //   to={{
      //     pathname: `/ms_editor/${id}`,
      //   }}
      //   target="_blank"></Link>;
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "#FF8C69",
        userSelect: "none",
      },
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">
              Welcome,<strong> &nbsp; Name</strong>
            </div>

            <UserProfile />
          </div>

          <DataTable
            title=""
            columns={cols}
            data={dataList}
            keyField={"id"}
            // defaultSortField={(row) => row.title}
            defaultSortFieldId={2}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            selectableRows={false}
            expandableRows={true}
            expandableRowDisabled={(row) => row.expanderDisabled}
            expandableRowsComponent={ExpandableComponent}
            expandableRowsComponentProps={(row) => row.id}
            pagination={true}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginationPerPage={10}
            onRowClicked={handleRowClicked}
            conditionalRowStyles={conditionalRowStyles}
            fixedHeader={true}
            subHeader={true}
            subHeaderComponent={subHeaderComponent}
            customStyles={customStyles}
          />
        </div>
      </div>
    </>
  );
};

export default ErrorList;

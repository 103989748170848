import React, {useState} from "react";
import axios from "axios";
import { AvatarChangeIcon, PasswordHide, PasswordShow } from "./icons.component";
import LeftNavigation from "./left-navigation";
import { useForm } from "react-hook-form";
import UserProfile from "./userProfile";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import reqHeader from "../helper/reqHeader";

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Old Password is required!")
    .min(6, "Your password must be a minimum of 6 characters long!"),
    newPassword: yup
    .string()
    .required("New Password is required!")
    .min(6, "Your password must be a minimum of 6 characters long!"),
    confirmpassword: yup
    .string()
    .required("Confirm Password is required!")
    .min(6, "Confirm password must be a minimum of 6 characters long!")
    .oneOf([yup.ref("newPassword"), null], "Passwords does not match"), 
});






const ChangeSuperPassword = () => {

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

   const dispatch = useDispatch();

   const { register, handleSubmit, errors, setValue } = methods;

   const [passwordShown, setPasswordShown] = useState(false);
   const [newpasswordShown, setNewPasswordShown] = useState(false);
   const [conpasswordShown, setConPasswordShown] = useState(false);
 
   const togglePasswordVisiblity = () => {
     setPasswordShown(passwordShown ? false : true);
   };
   const toggleNewPasswordVisiblity = () => {
     setNewPasswordShown(newpasswordShown ? false : true);
   };
   const toggleConPasswordVisiblity = () => {
     setConPasswordShown(conpasswordShown ? false : true);
   };


const submitHandler = async(data) => {
   const {currentPassword,newPassword} = data;

   const url = `${process.env.REACT_APP_URL}/transcription/updatesuperpassword/`;

   const headers = reqHeader(true, true, true);
   
   const config = {
     headers,
   };
 
   const body = {
    currentPassword,
     newPassword,
   };
   console.log("body", body)

  try {
    const response = await axios.put(url, body, config);
    const result = await response.data;
    console.log("The result " + JSON.stringify(result));
    toast.success("Password Updated !!")
  } catch (error) {
    console.log(error);
    toast.error("Incorrect Old Password")
  }
};


  
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="container completeJobPage d-flex">
        <LeftNavigation />
        <ToastContainer/>
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">
              <span>Welcome,</span><span className="UName"> Admin</span>
            </div>
            <UserProfile />
          </div>
          <div className="myProfile-Content">
            <div className="myProfile-form">
              <div className="d-flex myProfile-formRow">
                <div
                  className={`inputWrap passwordField ${
                    errors.currentPassword ? "error" : ""
                }`}>
                  <input  type={passwordShown ? "text" : "password"} placeholder="Old Password"
                     ref={register}
                  name="currentPassword" />
                  <div className="passwordEye">
                      <i onClick={togglePasswordVisiblity}>
                        {passwordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                   {errors.currentPassword && (
                      <span className="inputErrorMsg">
                        {errors.currentPassword?.message}
                      </span>
                    )}
                  <div class="passwordEye">
                    {/* <PasswordHide /> */}
                  </div>
                </div>
                <div
                  className={`inputWrap passwordField ${
                    errors.newPassword ? "error" : ""
                }`}>
                  <input type={newpasswordShown ? "text" : "password"} placeholder="New Password"
                     ref={register}
                  name="newPassword" />
                  <div className="passwordEye">
                      <i onClick={toggleNewPasswordVisiblity}>
                        {newpasswordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.newPassword && (
                      <span className="inputErrorMsg">
                        {errors.newPassword?.message}
                      </span>
                    )}
                  <div class="passwordEye">
                    {/* <PasswordHide /> */}
                  </div>
                </div>
                <div
                  className={`inputWrap passwordField ${
                    errors.confirmpassword ? "error" : ""
                }`}>
                  <input type={conpasswordShown ? "text" : "password"} placeholder="Confirm Password" 
                     ref={register}
                  name="confirmpassword" />
                  <div className="passwordEye">
                      <i onClick={toggleConPasswordVisiblity}>
                        {conpasswordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                  </div>
                   {errors.confirmpassword && (
                      <span className="inputErrorMsg">
                        {errors.confirmpassword?.message}
                      </span>
                    )}
                  <div class="passwordEye">
                    {/* <PasswordHide /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="myProfile-action">
              <button type="submit" className="profile-update-btn">Change super password</button>
            </div>
          </div>          
        </div>
      </div>
    </form>
  );
};

export default ChangeSuperPassword;

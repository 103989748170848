import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { login, clearMessage } from "../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Logo from "../assets/images/Logo.svg";
import TexlangLogo from "../assets/images/texlang-Logo.svg";
import { PasswordHide, PasswordShow } from "./icons.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tr } from "date-fns/locale";
import axios from "axios";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch.jsx";


const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!"),
  password: yup
    .string()
    .required("Password is required !!!")
    .min(6, "Password should be 6 characters"),
});



const TranscriberLogin = () => {

  const { register, handleSubmit, watch, errors, clearErrors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  let { loading, authenticated, error, msg, token } = userLogin;
  const [passwordShown, setPasswordShown] = useState(false);
  let [isManager, setIsManager] = useState(false);
  const onManagerChecked = (checked) => {
    console.log("checked",checked)
    setIsManager(checked);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if(error){
     toast.error(error)
     dispatch(clearMessage(userLogin))
    }
  }, [error])


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const submitHandler = (data) => {
    console.log("Login Data in Submit Handler is ", data);
    data.isManager = isManager;
    dispatch(login(data));
    
  };

  // if(userLogin.success==true && token){
  //   return <Redirect to="/dashboard" />
  // }
  if(userLogin.success==true && token)
  {
    //console.log("role..",userLogin.user.role);
    if(userLogin.user.role.trim()=="AdmPR")
    {
      //console.log("inside admPR..",userLogin.user.role);
      return <Redirect to="/admprdashboard" />
    }
    else
    {
      return <Redirect to="/dashboard" />
    }
}
 

  return (
    <>
      <div className="container RegisterWrap d-flex">
         <ToastContainer />
        <div className="registerLHS">
          <div className="logoSlogan-container">
            <div className="LogoWrap">
              <img src={TexlangLogo} alt="Logo" className="logo" />
            </div>

            <div className="poweredBy">
              <span>Powered by</span> <img src={Logo} alt="Logo" className="megdapLogo" />
            </div>
          
          </div>
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }} 
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
          <div className="loginTitle"><strong>Login</strong> to your Account</div>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">
                
                <div
                  className={`inputWrap emailField ${
                    errors.email ? "error" : ""
                  }`}>
                  <input
                    ref={register}
                    type="text"
                    placeholder="Email ID"
                    name="email"
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div
                  className={`inputWrap passwordField ${
                    errors.password ? "error" : ""
                  }`}>
                  <input
                    ref={register}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                  />
                  <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                    {/* <PasswordHide /> */}
                  </div>
                  {errors.password && (
                    <span className="inputErrorMsg">
                      {errors.password?.message}
                    </span>
                  )}
                  {/* {error && <span className="inputErrorMsg">{error}</span>} */}
                </div>
              
                <div className="remember-forgotPass d-flex">
                <div className="IsmanagerAccess">
                    Manager Access? &nbsp;
                    <ToggleSwitch
                      id="isManager"
                      checked={isManager}
                      onChange={onManagerChecked}
                    />
                  </div>
                  <div className="Forgotpassword">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>

                <div className="formFooter">
                  <button>SIGN IN</button>
                  <div className="loginLink">
                    Not a Member? <Link to="/transcriberregister">Register Now</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscriberLogin;
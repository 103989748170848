import { SearchIcon } from "../icons.component";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  return (
    <div className="projectSearch search">
      <input
        type="text"
        placeholder="Search Projects"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      />
      <button className="searchBtn">
        <SearchIcon />
      </button>
    </div>

    //   <div className="container h-100">
    //     <div className="d-flex flex-row-reverse justify-content-left h-100">
    //       <div className="searchbar">
    //         <input
    //           className="search_input"
    //           type="text"
    //           name=""
    //           placeholder="Search..."
    //           value={globalFilter || ""}
    //           onChange={(e) => setGlobalFilter(e.target.value || undefined)}
    //         ></input>
    //         <a href="#" className="search_icon">
    //           <i className="fas fa-search"></i>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
  );
};

export default GlobalFilter;

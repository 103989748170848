//NPM Packages
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { CustomDialog } from "react-st-modal";

//Styles
import "../../src/assets/style/normalize.css";
//Helper Files
import reqHeader from "../helper/reqHeader.js";

//Components
import Table from "./Table/Table.jsx";
//import ProgressBar from "../Utils/ProgressBar.jsx";
//import PrjtAssign from "./PrjtAssign";

const CustProjectList = (props) => {
  const {
    serviceType,
    type,
    setCustomer,
    totalDuration,
    uploadedDuration,
    handledBy,
    status,
    progress,
  } = props;

  const [data, setData] = useState([]);

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "custName",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Language Domain List">
                {row.original.custName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Language Domain List">
                {row.original.projectName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Total",
        accessor: "totalDuration",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !totalDuration,
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Language Domain List">
                {`${row.original.totalDuration}`}
              </span>
            </div>
          );
        },
      },
      {
        Header:
          type === "track" || type === "assign"
            ? "Progress / Uploaded"
            : " Progress / Total",
        accessor: "uploadedDuration",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !uploadedDuration,
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Language Domain List">
                {type === "track"
                  ? `${row.original.completedDuration} / ${row.original.uploadedDuration}`
                  : type === "assign"
                  ? `${row.original.assignedDuration} / ${row.original.uploadedDuration}`
                  : `${row.original.uploadedDuration} / ${row.original.totalDuration}`}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Handled By",
        accessor: "assignedPM",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !handledBy,
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              {value !== "" && value !== undefined && value !== null ? (
                <span title="Click for the detailed Language Domain List">
                  {value}
                </span>
              ) : (""
                // <button className="rateBtn">Assign</button>
              )}
            </div>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "stage",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   hidden: !status,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original, column)}
      //       >
      //         <span title="Click for the detailed Language Domain List">
      //           {row.original.stage}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Completed (%)",
      //   accessor: "percentageOfCompletion",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   hidden: !progress,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original, column)}
      //       >
      //         <ProgressBar completed={row.original.percentageOfCompletion} />
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    const getCustomers = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/transcription/customerProjectList`;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          setData(data);
        })
        .catch((err) => {
          setData([]);
        });
    };

    getCustomers();
  }, []);

  const handleClick = async (row, column) => {
    debugger;
    if (row !== undefined) {
      console.log("Customer row");
      if (column.id === "assignedPM") {
        if (row.assignedPM !== "") setCustomer(row);
        // else {
        //   await CustomDialog(<PrjtAssign selectedRow={row} />, {
        //     title: "Project Assign",
        //     showCloseIcon: true,
        //     className: { minwidth: "500px" },
        //   });
        // }
      } else {
        setCustomer(row);
      }
    } else {
      setCustomer({});
    }
  };

  return (
    <div className="tableContainer">
      {/* <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
      </div> */}

      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="Projects"
        isMultiSelect={false}
        // setSelectedRows={setSelectedRows}
        // setSelectedRowsValues={setSelectedRowsValues}
        // setClickedRow={setClickedRow}
      />
    </div>
  );
};

export default CustProjectList;

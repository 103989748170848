import React from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopupHandleBy = (props) => {
  const { fid } = props;

  console.log("fid,stage from tablelist..", fid);

  // const methods = useForm({
  //  // mode: "onBlur",
  //   //resolver: yupResolver(schema),
  // });
  const { register, handleSubmit, errors } = useForm();

  // use this hook to control the dialog
  const dialog = useDialog();

  const submitHandler = async (data) => {
    // e.preventDefault();
    // e.persist()

    console.log("Inside Submit Handler data ", data);
    const headers = reqHeader(true, true, true);

    let body = {
      fid: fid,
      handledBy: data.handledBy.replaceAll(/\\n/g, " "),
    };
    const config = { headers };
    let url = `${process.env.REACT_APP_URL}/transcription/transcription/handledby`;

    await axios.put(url, body, config).then((res) => {
      toast.success(res.data.msg);
      dialog.close();
      window.location.reload();
    });
  };

  return (
    <div className="SupportFilePopup">
      <form className="form" onSubmit={handleSubmit(submitHandler)}>
        <div className="addProjectDetails">
          <div className="inputWrap rejectFormWrap">
            <label className="label">Details</label>
            <input
              type="text"
              name="handledBy"
              ref={register}
              maxLength={100}
            />
          </div>
          <div className="popupFooter d-flex">
            <button className="orangeBtn btn">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PopupHandleBy;

import React from "react";
import { ProfileIcon, PWDChangeIcon, SignOut } from "./icons.component";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions.js";
import UserAvatar from 'react-user-avatar';

const UserProfile = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const userType = user.userType;
  //console.log("userType userprofile", userType)

  const history = useHistory();
  const dispatch = useDispatch();

    return (
      <>
        <div className="userInfoWrap">

          <div className="userName">
            {user.fullName}
          </div>
          <div className="userAvatar"><UserAvatar name={user.fullName} size="41" round="50%" textSizeRatio={2} color="#0AC6E8" /></div>
          {userType && userType === "Manager" ? 
          (<ul className="profileOptions">
          <li className="profileItem" onClick={()=>history.push("/myprofile")}>
            <ProfileIcon />
            <span>my profile</span>
          </li>
         
          <li className="changePwdItem" onClick={()=>history.push("/changepassword")}>
            <PWDChangeIcon />
            <span>change password</span>
          </li>

          <li className="changePwdItem" onClick={()=>history.push("/changesuperpassword")}>
            <PWDChangeIcon />
            <span>change super password</span>
          </li>

          <li className="signOutItem" onClick={()=>dispatch(logout())}>
            <SignOut />
            <span>Sign Out</span>
          </li>
        </ul>) :
        (
          <ul className="profileOptions">
            <li className="profileItem" onClick={()=>history.push("/myprofile")}>
              <ProfileIcon />
              <span>my profile</span>
            </li>

            <li className="signOutItem" onClick={()=>dispatch(logout())}>
              <SignOut />
              <span>Sign Out</span>
            </li>
          </ul>
        )
        }
        </div>
      </>
    );
};

export default UserProfile;
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import { Redirect,useHistory} from "react-router-dom";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  reason: yup
    .string()
    //.matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
    .required("Please enter the Reason for Rejection!"),
  
});

const PopupRejectFile = (props) => {
  const { fid ,stage, custtype} = props;
  console.log("fid,stage from tablelist..",fid,stage);

  const [languages, setLanguages] = useState([]);

  // const methods = useForm({
  //  mode: "onSubmit",
  //   resolver: yupResolver(schema),
  // });

  const { register, handleSubmit, errors, reset, } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  // use this hook to control the dialog
  const dialog = useDialog();
  const history=useHistory();

  const submitHandler = async(data) => {
    //debugger;
    // e.preventDefault();
    // e.persist()
  
    console.log("Inside Submit Handler data ", data);
    const headers = reqHeader(true, true, true);
        
        let body={
          fid:fid,
          reason:data.reason.replace(/\s/g, " "),
          stage:stage,
          custType:custtype
        }
        console.log("body", body)
        const config = { headers };
        let url = `${process.env.REACT_APP_URL}/transcription/transcription/updtfilerejectstat`;
  
        await axios
          .put(url,body,config)
          .then((res) => {
            console.log("axios res...",res);
            toast.success(res.data.msg);
            dialog.close();
            //history.push('/dashboard');
            window.location.reload();
          });
    
  };

  
  return (
    <div className="SupportFilePopup">
         <form className="form" onSubmit={handleSubmit(submitHandler)}>
            <div className="addProjectDetails">
              <div className= {`inputWrap emailField rejectFormWrap ${errors.reason ? "error" : ""}`}>
                <label className="label">
                  Reason for Rejection
                </label>
                <textarea
                  type="textarea"
                  name="reason"
                  ref={register}
                  rows={30}
                />
                {errors.reason && (
                    <span className="inputErrorMsg">
                      {errors.reason?.message}
                    </span>
                  )}
              </div>
              <div className="popupFooter d-flex">
                <button className="orangeBtn btn" >Submit</button>
              </div>
            </div>
      </form>
    </div>
  );
};

export default PopupRejectFile;

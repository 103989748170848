import {
  USER_LOGIN_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  FORGOTPASSWORD_EMAIL_SUCCESS,
  FORGOTPASSWORD_EMAIL_FAIL,
  RESETPASSWORD_EMAIL_SUCCESS,
  RESETPASSWORD_EMAIL_FAIL,
  USER_MSG_UPDATE_REQUEST,
  USER_MSG_UPDATE_SUCCESS,
  USER_MSG_UPDATE_FAIL,
} from "../constants/userConstants.js";

export const userLoginReducer = (state = {}, action) => {
  const { type, payload } = action;

  // console.log("User Reducer Type is ", type);

  switch (type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, authenticated: false };
    case USER_REGISTER_SUCCESS:
      return { ...payload, loading: false, authenticated: true };
    case USER_LOGIN_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return { ...payload, loading: false, authenticated: true };
    case USER_LOGIN_FAIL:
    case USER_LOGOUT:
      return { ...payload, loading: false, authenticated: false };
    case USER_UPDATE_FAIL:
      return { ...payload, loading: false, authenticated: true };
    case FORGOTPASSWORD_EMAIL_SUCCESS:
      return { ...payload, loading: true, authenticated: false };
    case FORGOTPASSWORD_EMAIL_FAIL:
      return { ...payload, loading: false, authenticated: false };
    case RESETPASSWORD_EMAIL_SUCCESS:
      return { ...payload, loading: true, authenticated: false };
    case RESETPASSWORD_EMAIL_FAIL:
      return { ...payload, loading: false, authenticated: false };
    case USER_MSG_UPDATE_REQUEST:
      return { loading: true, authenticated: false };
    case USER_MSG_UPDATE_SUCCESS:
      return { ...payload, loading: false, authenticated: false };
    case USER_MSG_UPDATE_FAIL:
      return { ...payload, loading: false, authenticated: false };
    default:
      return state;
  }
};

import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useExpanded,
  usePagination,
} from "react-table";
import GlobalFilter from "./GlobalFilter.jsx";
import { SortIconUP, SortIcon } from "../icons.component";

const Table = ({
  filter,
  title,
  count,
  pagination,
  columns,
  data,
  renderRowSubComponent,
}) => {
  let pageSizeArr = [];

  if (count <= 10) {
    pageSizeArr.push("10");
  } else if (count > 10 && count <= 25) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
  } else if (count > 25 && count <= 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
  } else if (count > 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
    pageSizeArr.push("100");
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { pageIndex, pageSize } = state;
  console.log("pagination", pagination, pageSize)
  const paginationControl = pagination ? (
    <div className="paginationWrap d-flex">
      <select
        className="showpages"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        disabled={count <= 10}>
        {pageSizeArr.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <div>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          {"  "}
        </span>
        <span>
          | Go to Page{" "}
          <span className="inputWrap">
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              onKeyPress={(e) => {
                var inputKeyCode = e.key;
                if (inputKeyCode != null) {
                  if (inputKeyCode == "-") e.preventDefault();
                }
              }}
            />
          </span>
        </span>
      </div>

      <div className="paginationAction">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button
          className="prevBtn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}>
          &lt; Prev
        </button>
        <button
          className="NextBtn"
          onClick={() => nextPage()}
          disabled={!canNextPage}>
          Next &gt;
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
    </div>
  ) : (
    ""
  );

  return (
    <>
      {/* <pre>
        <code>{JSON.stringify({ expanded: state.expanded }, null, 2)}</code>
      </pre> */}
      <div className= "tableWrap projectTableWrap block">
        <header className="d-flex">
          <h2>
            {title} <span className="projectCount">({count})</span>
          </h2>

          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />

          {/* <div className="projectSearch search">
          <input type="text" placeholder="Search Projects" />
          <button className="searchBtn">
            <SearchIcon />
          </button>
        </div> */}
        </header>

        <div className="projectTable">
          {/* React Table Will come here */}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="sortDown">
                              <SortIconUP />
                            </span>
                          ) : (
                            <span className="sortUp">
                              <SortIconUP />
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment>
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>

                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
            {/* <tfoot>
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <td {...column.getFooterProps()}>{column.render("Footer")}</td>
            ))}
          </tr>
        ))}
      </tfoot> */}
          </table>
          {paginationControl}
        </div>
      </div>
    </>
  );
};

export default Table;

export default function validate(
    value,
    segduration,
    spkid,
    timestamp
    ) {
    let errors = [];
    let segmentdata = [];
  
    //check Blank segment
    if (!value || value == "" || value == " ") {
      let SegmentDataEmpty = "Segment data is empty";
      segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
    }
    //more than 20 seconds check on segment length
    //debugger;
    
    if (timestamp>0 && segduration >= timestamp) {
      let TwentySecondError =
        `Segment is of more than ${timestamp} seconds duration, please split into smaller segments`;
      segmentdata.push({ TwentySecondError: TwentySecondError });
    }

    if(timestamp==0 && segduration >= 20)
    {
      let TwentySecondError =
        `Segment is of more than 20 seconds duration, please split into smaller segments`;
      segmentdata.push({ TwentySecondError: TwentySecondError });
    }
    
    ////Special characters check
    let specialcharactersregex = /[\@#$%^&*()<>":{}\/`~;_\[\]]/g
    let SpecialCharacters = specialcharactersregex.test(value);
  
    if (SpecialCharacters) {
      if (!value.match(/([\,.'!?\-]+[\\]+\S*)/)) {
        SpecialCharacters = "Special characters found.";
        segmentdata.push({ SpecialCharacters: SpecialCharacters });
      }
    }

    //Speaker ID missing check
    console.log("spkid", spkid)
    if (
        (spkid ==
        "" || spkid == false || spkid == "defaultvalue" || spkid == "undefined")
    ) {
        let speakerIdEmpty = "Speaker Id is not added for this segment";
        segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
    }

  
    errors.segmentdata = segmentdata;
    return errors;
  }
  
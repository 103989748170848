import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { PlusIcon } from "./icons.component";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";

const Tile = (props) => {
  const [projectCount, setProjectCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const history = useHistory();
  const { title, titleType, theme, url } = props;

  console.log("props", props)

  const userLogin = useSelector((state) => state.userLogin);
  const vendType = userLogin.user.vendType;
 
  const tileTheme = `projectBlock ${theme}`;

  useEffect(() => {
    async function getJobCount() {
      const params = { type: vendType };
      const url =
        titleType === "TranscriptionPending"
          ? `${process.env.REACT_APP_URL}/transcription/transcription/onassignfilecount`
          : titleType === "TranscriptionOngoing"
          ? `${process.env.REACT_APP_URL}/transcription/transcription/oninprogressfilecount`
          : titleType === "TotalEarnings"
          ? `${process.env.REACT_APP_URL}/transcription/transcription/totalearnings`
          // : titleType === "Dataproducts"
          // ? null
          : null;
      const headers = reqHeader(true, true, true);
      const config = { headers, params };

      axios
        .get(url, config)
        .then((res) => {
          console.log("res job count..",res);
          setProjectCount(res.data.data);
          setProgressCount(res.data.progress);
        })
        .catch((err) => {
          setProjectCount(0);
          setProgressCount(0);
        });
    }
    getJobCount();
  }, []);


  return (
    <li className={theme}>
      <div className="circle"></div>
      <div className="numbers">{projectCount}</div>
      {/* <h2 className="projecttitle">{title}</h2> */}
      <div className="projectStats">
          <div>{title}</div>
      </div>
    </li>
  );
};

export default Tile;

import React, { useState, useEffect } from "react";
import { AddSpeakerBtn, SpeakerRemove } from "../icons.component";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomDialog, useDialog } from "react-st-modal";
import axios from "axios";
import reqHeader from "../../helper/reqHeader";

const AddSpeakerContent = (props) => {
  let id = props.Fileid;
  let langname = props.lang;
  let allPeaks = props.allPeaks;
  let stage = props.stage
  //console.log("langname", stage);

  const dialog = useDialog();

  const [genderOptions] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Unsure", label: "Unsure" },
  ]);

  const [ageGroupOptions] = useState([
    { value: "Kid", label: "Kid" },
    { value: "Adult", label: "Adult" },
    { value: "Unsure", label: "Unsure" },
  ]);

  // const [speakerDialectOptions] = useState([
  //   { value: "Hindi", label: "Hindi" },
  //   { value: "KanojiHindi", label: "KanojiHindi" },
  //   { value: "BihariHindi", label: "BihariHindi" },
  // ]);

  const [speakerDialectOptions, setSpeakerDialectOptions] = useState([]);
  const [rowAdded, setRowAdded] = useState([]);
  const [dialect, setDialect] = useState("");
  const [flag, setFlag] = useState()

  useEffect(() => {
    async function getSpeakerDetails() {
      const url = `${process.env.REACT_APP_URL}/transcription/editor/getspeakerdetails`;

      const headers = reqHeader(true, true, true);

      const params = {
        id,
        language: langname,
      };

      const config = { headers, params };

      const { data } = await axios.get(url, config);
      console.log("setRowAdded", data.data.speakerDetails)
      setRowAdded(data.data.speakerDetails);
      setDialect(data.data.speakerdialect);
      //console.log("data", data.data.speakerDetails)
    }

    async function getDialects() {
      const url = `${process.env.REACT_APP_URL}/transcription/language/getactivelanguagedialects`;

      const headers = reqHeader(true, true, true);

      const params = {
        langname,
      };

      const config = { headers, params };

      const { data } = await axios.get(url, config);
      console.log("dialects data..", data);
      setSpeakerDialectOptions(data.data.dialects);

      //console.log("data", data.data.speakerDetails)
    }

    if (id !== undefined && langname !== undefined) {
      getDialects();
      getSpeakerDetails();
    }
  }, [id, langname]);

  //Handle all input and dropdown
  const OnChangeHandleInput = (e, i) => {
    const { name, value } = e.target;
    const values = [...rowAdded];
    values[i][name] = value.trim();
    console.log("values", values)

    setRowAdded(values);
  };

  const OnChangeHandleDailect = (e) => {
    //console.log("dialect", e.target.value)
    setDialect(e.target.value);
  };

  //Add new row
  const handleAddRow = (e) => {
    e.preventDefault();
    const values = [...rowAdded];
    console.log(values);
    values.push({
      speakerid:"",
      speakername: "",
      gender: "",
      agegroup: "",
    });
    setRowAdded(values);
  };

  //Remove existing row
  const onChangehandleRemove = async(i, row) => {
    console.log("row", row)
    const values = [...rowAdded];
    values.splice(i, 1);
   
    setRowAdded(values);

    let segmentarray = [];

    allPeaks.segments._segments.map((sdata, index) => {
      const TotalSeconds = (sdata._endTime - sdata._startTime).toFixed(3);
      console.log("rowadded speakerdetails", values.includes(sdata.speakerId))
      if(index == 0){
        let segmentdata = {
          id: sdata._id,
          startTime: sdata._startTime,
          segmentData: encodeURI((sdata._labelText).trim()),
          endTime: sdata._endTime,
          speakerId: sdata.speakerId == row.speakerid ? "" : sdata.speakerId,
          gender: sdata.speakerId == row.speakerid ? "" : sdata.gender,
          agegroup: sdata.speakerId == row.speakerid ? "" : sdata.agegroup,
          subsegments:sdata.SubSegments,
          sentiment:sdata.sentiment,
          isChecked:sdata.isChecked,
          speakerDetails:JSON.stringify(values)
        };
        segmentarray.push(segmentdata);
      }else{
        let segmentdata = {
          id: sdata._id,
          startTime: sdata._startTime,
          segmentData: encodeURI((sdata._labelText).trim()),
          endTime: sdata._endTime,
          speakerId: sdata.speakerId == row.speakerid ? "" : sdata.speakerId,
          gender: sdata.speakerId == row.speakerid ? "" : sdata.gender,
          agegroup: sdata.speakerId == row.speakerid ? "" : sdata.agegroup,
          subsegments:sdata.SubSegments,
          sentiment:sdata.sentiment,
          isChecked:sdata.isChecked
        };
        segmentarray.push(segmentdata);
      }
    });

    const url = `${process.env.REACT_APP_URL}/transcription/editor/savejsondata`;

    const headers = reqHeader(true, true, true);
    console.log("header", headers);
    const body = {
      segmentarray,
      FileId: id,
      stage: stage,

    };
    console.log("body", body);
    const config = { headers };

    const { data } = await axios.put(url, body, config);

    if (data.success) {
      //toast.success(data.msg);
      setFlag(true)
    }
  };

  //Submit data
  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let errors = [];
    let inputdata = [];
    let speakererror = "";
    let speakerId = "";
    let genderEmpty = "";
    let agegrouprEmpty = "";
    let dialectEmpty = ""


    const rowArray = rowAdded.map((data, i) => {
      if (!data.speakerid || data.speakerid == "" || data.speakerid == " ") {
        speakerId = `Row ${i + 1} Speaker id is empty`;
        toast.error(speakerId)
      }
      if (!data.gender || data.gender == "" || data.gender == " " || data.gender == "Select Gender") {
        genderEmpty = `Row ${i + 1} Gender is empty`;
        toast.error(genderEmpty)
      }
      if (!data.agegroup || data.agegroup == "" || data.agegroup == " " || data.agegroup == "Select Agegroup") {
        agegrouprEmpty = `Row ${i + 1} Age group is empty`;
        toast.error(agegrouprEmpty)
      }

      let regex = /[A-Z][1-9]/g;
      let singleCharNum = regex.test(data.speakerid);

      if (!singleCharNum) {
        speakererror =
          "Invalid speaker id. Use single char and single digit number. ";
      }
      return data.speakerid;
    });

    
    if (!dialect || dialect == "" || dialect == "Please Select Dialect") {
       dialectEmpty = `Dialect is empty`;
      toast.error(dialectEmpty);
    }

    let isDuplicate = rowArray.some((item, idx) => {
      return rowArray.indexOf(item) != idx;
    });

    if (isDuplicate == true) {
      toast.error("Speakerid should be unique!");
    }

    errors.inputdata = inputdata;
    if (errors.inputdata.length > 0) {
      //alert("All fields are required!")
      toast.error("All fields are required!");
    }

    if (speakererror != "") {
      toast.error(speakererror);
    }

    if (
      isDuplicate != true && errors.inputdata.length == 0 && speakererror == "" &&
      dialectEmpty == "" && speakerId == "" && genderEmpty == "" && agegrouprEmpty == ""
    ) {
      const url = `${process.env.REACT_APP_URL}/transcription/editor/updatespeakerdetails`;

      const headers = reqHeader(true, true, true);

      const body = {
        id,
        dialect,
        rowdetails: rowAdded,
      };

      console.log("body", body);
      const config = { headers };

      let { data } = await axios.put(url, body, config);
      
      if(flag == true){
        if (data.success && data.msg) {
          toast.success(data.msg);
          dialog.close(data);
          window.location.reload()
        }
      }else{
        if (data.success && data.msg) {
          toast.success(data.msg);
          dialog.close(data);
        }
      }
      
    }
  };

  //speakerDetails component
  const speakerDetails = rowAdded.map((row, idx) => {
    console.log("row, idx", row, idx);
    //console.log("errors", errors)
    return (
      <ul className="speaker-list d-flex" key={`${rowAdded}-${idx}`}>
        <li className="speaker-item">
          {" "}
          {/* speakerFieldError  */}
          <input
            type="text"
            placeholder="Speaker Id"
            name="speakerid"
            //ref={register}
            value={row.speakerid}
            // disabled={row.speakerid}
            onChange={(e) => OnChangeHandleInput(e, idx)}
          />
        </li>
        <li className="speaker-item">
          {" "}
          {/* speakerFieldError  */}
          <input
            type="text"
            placeholder="Speaker Name"
            name="speakername"
            //ref={register}
            value={row.speakername}
            // disabled={row.speakername}
            onChange={(e) => OnChangeHandleInput(e, idx)}
          />
        </li>
        <li className="speaker-item">
          <select
            //className="selectGenderAge"
            //value={optionvalue}
            name="gender"
            value={row.gender}
            disabled={row.gender}
            onChange={(e) => OnChangeHandleInput(e, idx)}>
            <option>Select Gender</option>
            {genderOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </li>
        <li className="speaker-item">
          <select
            //className="selectGenderAge"
            //value={optionvalue}
            name="agegroup"
            value={row.agegroup}
            disabled={row.agegroup}
            onChange={(e) => OnChangeHandleInput(e, idx)}>
            <option>Select Agegroup</option>
            {ageGroupOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </li>
        <li
          className="speaker-item deleteCol"
          onClick={() => onChangehandleRemove(idx, row)}>
          <SpeakerRemove />
        </li>
      </ul>
    );
  });

  return (
    <div className="popupInner">
      <form className="popupContent addSpeaker" onSubmit={submitHandler}>
        <div className="dilectWrap d-flex">
          <label> Dialect</label>
          <select
            //className="selectGenderAge"
            //value={optionvalue}
            name="speakerdialect"
            value={dialect}
            onChange={(e) => OnChangeHandleDailect(e)}>
            <option>Please Select Dialect</option>
            {speakerDialectOptions &&
              speakerDialectOptions.map((item) => (
                <option key={item._id} value={item.name}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <ul className="speakerHeader d-flex">
          <li>
            Speaker Id<span className="required">*</span>
          </li>
          <li>Speaker Name</li>
          <li>
            Gender<span className="required">*</span>
          </li>
          <li>
            Age Group<span className="required">*</span>
          </li>
          <li className="deleteCol"></li>
        </ul>
        <div className="speakerContentWrap">{speakerDetails}</div>

        <footer>
          <button className="addSpeakerBtn" onClick={(e) => handleAddRow(e)}>
            <AddSpeakerBtn />
            <span>ADD SPEAKER</span>
          </button>
          <button className="btnBlue">SAVE & CLOSE</button>
        </footer>
      </form>
    </div>
  );
};

export default AddSpeakerContent;

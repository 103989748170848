//Packages
import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { useHistory,Link  } from "react-router-dom";
import { format } from "date-fns";
import { CustomDialog } from "react-st-modal";

//Custom Components
import Table from "./Table/Table.jsx";
//import ProgressBar from "../Utils/ProgressBar.jsx";
// import FileAssignBatch from "./FileAssignBatch.jsx";
// import FileCancelBatch from "./FileCancelBatch.jsx";
// import FileClosureBatch from "./FileClosureBatch.jsx";
//import AudioPlayers from "../Downloads/AudioPlayers.jsx";

//Helper Files
import reqHeader from "../helper/reqHeader.js";
// import DownloadFiles from "../Downloads/DownloadFiles";
// import { DownloadIcon } from "../../svgIconComponent/svg_icon_components";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const FileList = (props) => {
  debugger;
  console.log("props inside filelist..",props);
  const {
    type,
    clickedLangDomain,
    reload,
    customer,
    progress,
    download,
    multiSelect,
    preview,
  } = props;

  const [data, setData] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [isAssigned, setIsAssigned] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isClosure, setIsClosure] = useState(false);

  const spanLinkStyle = {
    cursor: "pointer",
    color: "#22aaf0",
    textDecoration: "underline",
    fontWeight: "bold",
  };

  // const checkBoxCols = multiSelect
  //   ? {
  //       id: "selection",
  //       // The header can use the table's getToggleAllRowsSelectedProps method
  //       // to render a checkbox
  //       Header: ({ getToggleAllRowsSelectedProps }) => (
  //         <div>
  //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       ),
  //       // The cell can use the individual row's getToggleRowSelectedProps method
  //       // to the render a checkbox
  //       Cell: ({ row }) => (
  //         <div>
  //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  //         </div>
  //       ),
  //     }
  //   : {
  //       id: "_id",
  //       Header: "Id",
  //       accessor: "_id",
  //       sortType: "basic",
  //       filter: "text",
  //     };
  let title=clickedLangDomain.language+"-"+clickedLangDomain.domain+"-"+clickedLangDomain.frequency+" Files";

  let columns = 
  useMemo(() => 
  [
      {
        Header: "File Name",
        accessor: "fileName",
        filter: "text",
        //className: 'col-firstName',
        Cell: ({ row }) => {
          return  <span
          style={spanLinkStyle}
          onClick={() => admPRrowClicked(row.original)}>
          {row.original.fileName}
        </span>
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        style: {},
        width: 100,
        Cell: ({ value }) => {
          return (
            <span title={format(new Date(value), "dd/MM/yyyy")}>
              {format(new Date(value), "dd/MM/yyyy")}
            </span>
          );
        },
      },
      {
        Header: type === "track" ? "Progress / Total" : "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <span
              title={
                type === "track"
                  ? `${row.original.completedDuration} / ${row.original.fileDuration}`
                  : `${row.original.fileDuration}`
              }
            >
              {type === "track"
                ? `${row.original.completedDuration} / ${row.original.fileDuration}`
                : `${row.original.fileDuration}`}
            </span>
          );
        },
        width: 150,
      },
      {
        Header: "Handled By",
        accessor: "handledByEmail",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value }) => {
          return <span title={value}>{value}</span>;
        },
      },
      {
        Header: "Assigned On",
        accessor: "assignedOn",
        sortType: "basic",
        //filter: "text",
        //className: 'col-salary',
      },
      // {
      //   Header: "Handled By",
      //   accessor: "handledBy",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: true,
      //   width: 200,
      //   Cell: ({ value }) => {
      //     return <span title={value}>{value}</span>;
      //   },
      // },
      {
        Header: "Status",
        accessor: "stage",
        sortType: "basic",
        filter: "text",
        width: 200,
        Cell: ({ value, row }) => { 
           return (row.original.transcriptionCancelled == true || row.original.proofreadingCancelled == true || row.original.stage == "Proof Reader Rejected") ? <span style={{color:"red"}} title={value}>{value}</span> : <span title={value}>{value}</span>
        },
      },
      
    ],
    []
 );

  useEffect(() => {
    //debugger;
    const getFiles = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/transcription/projectFileList`;
      const headers = reqHeader(true, true, true);
      const params = {
        customerID: clickedLangDomain.customerID,
        projectID: clickedLangDomain.projectID,
        language: clickedLangDomain.language,
        domain: clickedLangDomain.domain,
        frequency: clickedLangDomain.frequency,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          //console.log("data..",data);
          //debugger;
          let newData = data.map((value, index) => {
            const {
              stage,
              transcription,
              translation,
              subtitling,
              proofReading,
            } = value;
            

            let proofReader = proofReading.map((value) => value.name);

            let transcriber = transcription.map((value) =>
              value.status !== "Cancelled" ? value.name : ""
            );

            let TRCancelled = (stage === "Open" && transcription.length > 0) ? true : false;
            //console.log("TRCancelled", TRCancelled);

            let PRCancelled = (stage === "Transcription Completed" && proofReading.length > 0) ? true : false;
            //console.log("PRCancelled", PRCancelled);

            let percComplete = 0;
            let handledBy = "",
              handledByEmail = "",assignedOn="";
            if (transcription.length>0 && 
              (stage === "Transcription Assigned" ||
              stage === "Transcription" ||
              stage === "Transcription Completed")
            ) {
              percComplete =
                transcription[transcription.length - 1].percentageOfCompletion;
              handledBy = transcription[transcription.length - 1].name;
              handledByEmail = transcription[transcription.length - 1].email;
              assignedOn=transcription[transcription.length - 1].assignedOn;
            } else if (proofReading.length>0 && 
              (stage === "Proof Reading Assigned" ||
              stage === "Proof Reading" ||
              stage === "Proof Reading Completed" || stage === "Proof Reader Rejected")
            ) {
              percComplete =
              proofReading[proofReading.length - 1].percentageOfCompletion;
              handledBy = proofReading[proofReading.length - 1].name;
              handledByEmail = proofReading[proofReading.length - 1].email;
              assignedOn=proofReading[proofReading.length - 1].assignedOn;
            } else {
              percComplete = 0;
              handledBy = "";
              handledByEmail = "";
              assignedOn="";
            }

            // var durationTime = new Date(null);
            // //completedDuration is seconds.millisecs so we need to convert to millisecs by multiplying with 1000
            // durationTime.setMilliseconds(value.fileDurationSecs * 1000);
            // var durationTimeFormatted = durationTime
            //   .toISOString()
            //   // .substr(11, 8);
            //   .substr(11, 11);

            // var completedTime = new Date(null);
            // //completedDuration is seconds.millisecs so we need to convert to millisecs by multiplying with 1000
            // completedTime.setMilliseconds(value.completedDuration * 1000);
            // var completedTimeFormatted = completedTime
            //   .toISOString()
            //   // .substr(11, 8);
            //   .substr(11, 11);


            return {
              type: "child",
              serviceType: "Transcription",
              id: value._id,
              fileName: value.fileName,
              fileDuration: value.fileDuration,
              ETA: value.ETA,
              stage:
                value.stage === "Transcription"
                  ? "Transcription Inprogress"
                  : value.stage === "Proof Reading"
                  ? "Proof Reading Inprogress"
                  : value.stage,
              status: value.status,
              fileLocation: value.fileLocation,
              transcription: transcription,
              translation: translation,
              subtitling: subtitling,
              proofReading: proofReading,
              assignedOn: assignedOn!=""?format(new Date(assignedOn), "dd/MM/yyyy"):"",
              //assignedOn: "",
              transcriberName:
                value.stage === "Paid" || value.stage === "Open"
                  ? ""
                  : transcriber[transcription.length - 1],
              translatorName: "",
              subtitlerName: "",
              proofReaderName:
                value.stage === "Transcription Completed"
                  ? ""
                  : proofReader[proofReading.length - 1],
              assignedPM: "", //assignedPM is needed
              language: value.tgtLang,
              domain: value.domain,
              frequency: value.frequency,
              customerID: value.customerID,
              custName: value.custName,
              custType: value.custType,
              projectID: value.projectID,
              projectName: value.projectName,
              percentageOfCompletion: percComplete,
              completedDuration: value.completedDuration,
              handledBy: handledBy,
              handledByEmail: handledByEmail,
              targetFileLocation: value.targetFileLocation,
              targetFileName: value.targetFileName,
              targetOutputFormat: value.targetOutputFormat,
              targetJsonFileName: value.targetJsonFileName,
              targetJsonFileLocation: value.targetJsonFileLocation,
              transcriptionCancelled: TRCancelled,
              proofreadingCancelled: PRCancelled
            };
          });
          //console.log("newData..",newData);
          setData(newData);
        })
        .catch((err) => {
          console.log("err", err)
          setData([]);
        });
    };

    if (clickedLangDomain && Object.keys(clickedLangDomain).length !== 0) {
      getFiles();
    } else {
      setData([]);
    }
  }, [clickedLangDomain]);

  const refreshFiles = async () => {
    const url = `${process.env.REACT_APP_URL}/admin/transcription/projectFileList`;
    const headers = reqHeader(true, true, true);
    const params = {
      customerID: clickedLangDomain.customerID,
      projectID: clickedLangDomain.projectID,
      language: clickedLangDomain.language,
      domain: clickedLangDomain.domain,
      frequency: clickedLangDomain.frequency,
    };
    const config = { headers, params };

    await axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        let newData = data.map((value) => {
          const {
            stage,
            transcription,
            translation,
            subtitling,
            proofReading,
          } = value;

          let proofReader = proofReading.map((value) => value.name);

          let transcriber = transcription.map((value) =>
            value.status !== "Cancelled" ? value.name : ""
          );

          let percComplete = 0;
          let handledBy = "",
            handledByEmail = "";
          if (
            stage === "Transcription Assigned" ||
            stage === "Transcription" ||
            stage === "Transcription Completed"
          ) {
            percComplete =
              transcription[transcription.length - 1].percentageOfCompletion;
            handledBy = transcription[transcription.length - 1].name;
            handledByEmail = transcription[transcription.length - 1].email;
          } else if (
            stage === "Proof Reading Assigned" ||
            stage === "Proof Reading" ||
            stage === "Proof Reading Completed"
          ) {
            percComplete =
              proofReading[proofReading.length - 1].percentageOfCompletion;
            handledBy = proofReading[proofReading.length - 1].name;
            handledByEmail = proofReading[proofReading.length - 1].email;
          } else {
            percComplete = 0;
            handledBy = "";
            handledByEmail = "";
          }

          // var durationTime = new Date(null);
          // //completedDuration is seconds.millisecs so we need to convert to millisecs by multiplying with 1000
          // durationTime.setMilliseconds(value.fileDurationSecs * 1000);
          // var durationTimeFormatted = durationTime
          //   .toISOString()
          //   // .substr(11, 8);
          //   .substr(11, 11);

          // var completedTime = new Date(null);
          // //completedDuration is seconds.millisecs so we need to convert to millisecs by multiplying with 1000
          // completedTime.setMilliseconds(value.completedDuration * 1000);
          // var completedTimeFormatted = completedTime
          //   .toISOString()
          //   // .substr(11, 8);
          //   .substr(11, 11);

          return {
            type: "child",
            serviceType: "Transcription",
            id: value._id,
            fileName: value.fileName,
            fileDuration: value.fileDuration,
            ETA: value.ETA,
            stage:
              value.stage === "Transcription"
                ? "Transcription Inprogress"
                : value.stage === "Proof Reading"
                ? "Proof Reading Inprogress"
                : value.stage,
            status: value.status,
            fileLocation: value.fileLocation,
            transcription: transcription,
            translation: translation,
            subtitling: subtitling,
            proofReading: proofReading,
            transcriberName:
              value.stage === "Paid" || value.stage === "Open"
                ? ""
                : transcriber[transcription.length - 1],
            translatorName: "",
            subtitlerName: "",
            proofReaderName:
              value.stage === "Transcription Completed"
                ? ""
                : proofReader[proofReading.length - 1],
            assignedPM: "", //assignedPM is needed
            language: value.tgtLang,
            domain: value.domain,
            frequency: value.frequency,
            customerID: value.customerID,
            custName: value.custName,
            custType: value.custType,
            projectID: value.projectID,
            projectName: value.projectName,
            percentageOfCompletion: percComplete,
            completedDuration: value.completedDuration,
            handledBy: handledBy,
            handledByEmail: handledByEmail,
            targetFileLocation: value.targetFileLocation,
            targetFileName: value.targetFileName,
            targetOutputFormat: value.targetOutputFormat,
            targetJsonFileName: value.targetJsonFileName,
            targetJsonFileLocation: value.targetJsonFileLocation,
          };
        });

        setData(newData);
      })
      .catch((err) => {
        setData([]);
      });
  };

  useEffect(() => {
    if (data.length !== undefined && data.length !== null) {
      let open = data.filter((value) => value.stage === "Open");

      let assigned = data.filter(
        (value) =>
          value.stage === "Transcription Assigned" ||
          value.stage === "Proof Reading Assigned"
      );

      let inprogress = data.filter(
        (value) =>
          value.stage === "Transcription Inprogress" ||
          value.stage === "Proof Reading Inprogress"
      );

      let completed = data.filter(
        (value) =>
          value.stage === "Transcription Completed" ||
          value.stage === "Proof Reading Completed"
      );

      setOpenCount(open.length);
      setAssignedCount(assigned.length);
      setInProgressCount(inprogress.length);
      setCompletedCount(completed.length);
    }
  }, [data]);

  // useEffect(() => {
  //   if (selectedRowsValues.length > 0) {
  //     let rows = selectedRowsValues.map((value, index) => {
  //       return value.original;
  //     });

  //     let selectedStages = rows.map((value) => value.stage);

  //     let uniqueStage = [...new Set(selectedStages)];
  //     console.log("uniqueStage..",uniqueStage);

  //     if (uniqueStage.length === 1) {
  //       if (
  //         uniqueStage.toString() === "Transcription" ||
  //         uniqueStage.toString() === "Transcription Inprogress"
  //         ||
  //         uniqueStage.toString() === "Proofreading Inprogress" || 
  //         uniqueStage.toString() === "Proof Reading" || uniqueStage.toString() === "Proof Reading Inprogress"
  //       ) {
  //         setIsAssigned(false);
  //         setIsCancelled(true);
  //         setIsClosure(false);
  //       } else if (uniqueStage.toString() === "Transcription Assigned"
  //        || uniqueStage.toString() === "Proof Reading Assigned"
  //       ) {
  //         //setIsAssigned(true);
  //         setIsAssigned(false);
  //         setIsCancelled(true);
  //         setIsClosure(false);
  //       } else if (uniqueStage.toString() === "Open") {
  //         setIsAssigned(true);
  //         setIsCancelled(false);
  //         setIsClosure(false);
  //       } else if (uniqueStage.toString() === "Transcription Completed") {
  //         setIsAssigned(true);
  //         setIsCancelled(false);
  //         setIsClosure(false);
  //       } else if (uniqueStage.toString() === "Proof Reader Rejected") {
  //         //setIsClosure(true);
  //         setIsAssigned(true);
  //       } else if (uniqueStage.toString() === "Proof Reading Completed" ) {
  //         setIsClosure(true);
  //         //setIsAssigned(true);
  //       }
  //     } else {
  //       setIsAssigned(false);
  //       setIsCancelled(false);
  //       setIsClosure(false);
  //     }
  //   } else {
  //     setIsAssigned(false);
  //     setIsCancelled(false);
  //     setIsClosure(false);
  //   }
  // }, [selectedRowsValues]);

  const admPRrowClicked = async (row) => {
    console.log("Row inside the rowClicked is ", row);
    const { id,custCode } = row; //618a2ab74aaef50aa8c2d509
    let client ="admpreditor";
    let childWindow = window.open(`/${client}/${id}`, "_blank");
    var timer = setInterval(function () {
      if (childWindow.closed) {
        clearInterval(timer);
        //reload(true);
      }
    }, 1000);
  };


  // const handleFileAssignBatch = async () => {
  //   let rows = selectedRowsValues.map((value, index) => {
  //     return value.original;
  //   });

  //   await CustomDialog(<FileAssignBatch selectedRows={rows} />, {
  //     title: "File Assign",
  //     showCloseIcon: true,
  //     className: { minwidth: "500px" },
  //   });
  // };

  // const handleFileCancelBatch = async () => {
  //   let rows = selectedRowsValues.map((value, index) => {
  //     return value.original;
  //   });

  //   await CustomDialog(<FileCancelBatch selectedRows={rows} />, {
  //     title: "File Assign Cancel",
  //     showCloseIcon: true,
  //   });
  // };

  // const handleFileClosureBatch = async () => {
  //   let rows = selectedRowsValues.map((value, index) => {
  //     return value.original;
  //   });

  //   await CustomDialog(<FileClosureBatch selectedRows={rows} />, {
  //     title: "File Closure",
  //     showCloseIcon: true,
  //   });
  // };

  // const handleFilePreview = async (props) => {
  //   await CustomDialog(
  //     <AudioPlayers
  //       selectedRow={props}
  //       className="audioPlayer"
  //       style={{
  //         background: "#740AF6",
  //         padding: "40px",
  //         borderRadius: "5px",
  //       }}
  //     />,
  //     {
  //       title: "File Preview",
  //       showCloseIcon: true,
  //     }
  //   );
  // };

  // const handleDownload = async (row, value) => {
  //   await CustomDialog(<DownloadFiles selectedRow={row} />, {
  //     title: "File Downloads",
  //     showCloseIcon: true,
  //   });
  // };

  return (
    <div className="fileListTable">
      {/* <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
        <button className="table-btn">Export</button>
        <button className="redTable-btn" disabled={!isAssigned} onClick={handleFileAssignBatch}>Assign</button>
        <button className="redTable-btn" disabled={!isCancelled} onClick={handleFileCancelBatch}>Cancel</button>
        <button className="redTable-btn" disabled={!isClosure} onClick={handleFileClosureBatch}>Closure</button>
        <button className="greeTable-btn" onClick={refreshFiles}>Refresh</button>
      </div> */}

      <div className="tableBottomHeader">
        {/* <text className="text" style={{ color: "#E05A4F" }}>
          {" "}
          Open : 45
        </text>
        <text className="text" style={{ color: "#DD934E" }}>
          {" "}
          Assigned : 385{" "}
        </text>
        <text className="text" style={{ color: "#78CF4D" }}>
          {" "}
          Inprogress : 55
        </text>
        <text className="text" style={{ color: "#717394" }}>
          {" "}
          Completed : 84
        </text> */}
        
      </div>

      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        title={title}
        // hideTargetDownload={false}
        // hideInvoiceDownload={false}
        // hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="Files"
        // isMultiSelect={multiSelect}
        // // setSelectedRows={setSelectedRows}
        // setSelectedRowsValues={setSelectedRowsValues}
        // getHeaderProps={(column) => ({
        //   // onClick: () => console.log(`Header Column clicked`, column),
        // })}
        // getColumnProps={(column) => ({
        //   // onClick: () => console.log(`Column clicked`, column),
        // })}
        // getRowProps={(row) => ({
        //   // onClick: () => console.log(`row clicked`, row),
        //   // style: {
        //   //   background: row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
        //   // },
        // })}
        // getCellProps={(cellInfo) => ({
        //   // style: {
        //   //   backgroundColor: `hsl(${
        //   //     120 * ((120 - cellInfo.value) / 120) * -1 + 120
        //   //   }, 100%, 67%)`,
        //   // },
        // })}
      />
    </div>
  );
};

export default FileList;

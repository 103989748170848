import React, { Fragment } from "react";
import spinner from "../assets/images/spinner.gif";

export default ({spinneruploadtext }) => (
 // console.log(spinnertext,progress),
  <div className="fullPageLoader">
    <div className="overlay"></div>
    <div className="loaderInner">
      <img
        src={spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading....."
      />
      {/* <Spinner /> */}

      <div className="loadingTxt">
        <div>Loading... Please wait</div>
      </div> 

    
    </div>
  </div>
);

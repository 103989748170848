import React from "react";
import { useHistory } from "react-router";
import Logo from '../assets/images/texlang-Logo.svg';
import { CurrentJob, CompletedJob, MyEarnings, JobHistory, SignOut} from "./icons.component";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions.js";

function LeftNavigation(props) {
  console.log("props", props)
  const userLogin = useSelector((state) => state.userLogin);
  console.log("userLogin", userLogin)
  
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <div className="leftSideBar">
        <div className="LogoWrap">
          <img src={Logo} alt="Logo" className="LHSLogo" />
        </div>  
        {userLogin.user && userLogin.user.role != "AdmPR" ? (
                  <ul className="navigationWrap">
                  <li className="navItem" onClick={()=>history.push("/dashboard")}>
                    <CurrentJob />
                    <div className="navLabel">Current Jobs</div>
                  </li>
        
                  <li className="navItem" onClick={()=>history.push("/completedjob")} >
                    <CompletedJob/>
                    <div className="navLabel">Completed Jobs</div>
                  </li>
        
                  <li className="navItem" onClick={()=>history.push("/jobhistory")}>
                    <JobHistory />
                    <div className="navLabel">Job History</div>
                  </li>
                </ul>
        ):(
          <ul className="navigationWrap">
                  <li className="navItem" onClick={()=>history.push("/admprdashboard")}>
                    <CurrentJob />
                    <div className="navLabel">Current Jobs</div>
                  </li>
                </ul>
          )   
        }


        <div className="logoutLink navItem" onClick={()=>dispatch(logout())}>
          <SignOut />
          <div className="navLabel">Sign Out</div>
        </div>
      
      </div>
    </>
  );
}

export default LeftNavigation;
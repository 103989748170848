import React from "react";
import LeftNavigation from "./left-navigation";
import TableList from "./Table/TableList";
import UserProfile from "./userProfile";

const JobHistory = () => {
  
  return (
    <>
      <div className="container completeJobPage d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">
              <span>Welcome,</span><span className="UName"> Admin</span>
            </div>

            <UserProfile />
          </div>

          <TableList
            src="JobHistory"
            pagination={true}
          />
        </div>
      </div>
    </>
  );
};

export default JobHistory;

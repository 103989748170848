import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, registerClearMessage } from "../actions/userActions.js";
import Logo from "../assets/images/Logo.svg";
import TexlangLogo from "../assets/images/texlang-Logo.svg";
import { PasswordHide, PasswordShow } from "./icons.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Select from "react-select";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";


const schema = yup.object().shape({
  fname: yup
    .string()
    .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
    .required("First name is required!"),
  lname: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!")
    .required("Last name is required!"),
  email: yup
    .string()
    .required("Email ID is required!")
    .email("Enter a valid Email ID!"),
  // custMobile: yup
  //   .number() 
  //   .typeError("That doesn't look like a phone number")
  //   .positive("A phone number can't start with a minus")
  //   .integer("A phone number can't include a decimal point")
  //   .required('A phone number is required'),
  password: yup
    .string()
    .required("Password is required!")
    .min(6, "Your password must be a minimum of 6 characters long!"),
  cpassword: yup
    .string()
    .required("Confirm Password is required!")
    .min(6, "Confirm password must be a minimum of 6 characters long!")
    .oneOf([yup.ref("password"), null], "Passwords does not match"),
  srcLang: yup
    .array()
    .nullable()
    .required("Languages is required."),
  trascriberRole: yup
    .array()
    .nullable()
    .required("Role is required."),
  
});


const TranscriberRegister = () => {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });


  const { register, handleSubmit, errors, setValue } = methods;

  const [custMobile, setCustMobile] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory()

  const userLogin = useSelector((state) => state.userLogin);
  let { loading, authenticated, error, errorList, loginLocation, msg, token } = userLogin;



  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleCPasswordVisiblity = () => {
    setCPasswordShown(cpasswordShown ? false : true);
  };

  useEffect(() => {
    if(errorList && errorList.email && error){
     // console.log("Email", errorList)
      //alert(`Email ${error}`)
      toast.error(`Email ${error}`);
    }

    if(msg=="User Created Successfully. Our team will get back to you shortly."){
      toast.success(msg);
      setTimeout(()=>{
        history.push("/");
        //dispatch(registerClearMessage())
      }, 5000)
     
     
    }

  }, [errorList, msg]);

 

  const [srcLanguage, setSrcLanguage] = useState([]);
  useEffect(() => {
    
    async function getlanguages() {
      const url = `${process.env.REACT_APP_URL}/transcription/language/activeTranscriberLanguages`;
      const headers = reqHeader(true, true, true);
    console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Languages", res)
          setSrcLanguage(
            res.data.data.map(({ language }) => ({ label: language, value: language }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getlanguages();
  }, []);

  const [srcLang, setSrcLang] = useState([]);
  const OnChangeSourceLanguage = (item) => {
    //console.log("item",item);
    const target = item.map((vals) => vals.value);
    console.log("target",target);
    setValue("srcLang", target);
    setSrcLang(item);
    // setValue("trascriberRole", target);
    // setTranscriberRole(item);
  };

  const [vendortypes] = useState([
    { label: "Transcriber", value: "Transcriber" },
    { label: "Proof Reader", value: "Proof Reader" },
  ]);
  const [trascriberRole, setTranscriberRole] = useState([]);

  const OnChangeTranscriberRole = (item) => {
    const target = item.map((vals) => vals.value);
    setValue("trascriberRole", target);
    setTranscriberRole(item);
    // setValue("tgtLang", target);
    // setTgtLang(item);
  }

  const submitHandler = (data) => {
    if(data.parttime&&data.hours=="")
    {
      toast.error("Please Enter Hours Per Week.");
    }
    else if(data.srcLang.length == 0){
      toast.error("Please select Languages.")
    }else if(data.trascriberRole.length == 0){
      toast.error("Please select Transcriber Role.")
    }else{
      console.log("data", data)
      dispatch(registerUser(data));
    }
    
  };

  if(userLogin.success==true && token){
    return <Redirect to="/dashboard" />
  }
  
  // if(userLogin.success==true){
  //   return <Redirect to="/dashboard" />
  // }
  
  // if(msg && !authenticated){
  //   setTimeout(()=>{
  //     toast.success(msg);
  //   }, 3000)
    
  //     return <Redirect to ="/" />
  // }

    return (
      <>
        <div className="container RegisterWrap d-flex">
          <ToastContainer />
        <div className="registerLHS">
          <div className="logoSlogan-container">
            <div className="LogoWrap">
              <img src={TexlangLogo} alt="Logo" className="logo" />
            </div>

            <div className="poweredBy">
              <span>Powered by</span> <img src={Logo} alt="Logo" className="megdapLogo" />
            </div>
          </div>
          <div className="description">
            Copyright © Megdap Innovation Labs | {" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }} 
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
          <div className="registerRHS">
            <div className="registrationForm registerForm">
              <div className="loginTitle"><strong>Welcome,</strong> Sign up for an account</div>
              <div className="registerFormWrap">
              <form className="form" onSubmit={handleSubmit(submitHandler)}>
                <div className="firstLastField d-flex">
                  <div className={`inputWrap ${errors.fname ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="fname"
                      ref={register}
                    />
                    {errors.fname && (
                      <span className="inputErrorMsg">
                        {errors.fname?.message}
                      </span>
                    )}
                  </div>
                  <div className={`inputWrap ${errors.lname ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lname"
                      ref={register}
                    />
                    {errors.lname && (
                      <span className="inputErrorMsg">
                        {errors.lname?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className={`inputWrap emailField ${
                    errors.email ? "error" : ""
                  }`}>
                  <input
                    type="text"
                    placeholder="Email ID"
                    name="email"
                    ref={register}
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>
                <div className="firstLastField d-flex">
                  <div
                    className={`inputWrap ${
                      errors.skype ? "error" : ""
                    }`}>
                    <input
                      type="text"
                      placeholder="Skype ID"
                      name="skype"
                      ref={register}
                    />
                    {errors.skype && (
                      <span className="inputErrorMsg">
                        {errors.skype?.message}
                      </span>
                    )}
                  </div>

                  <div
                  className={`inputWrap ${
                    formatPhoneNumberIntl(custMobile) &&
                    !isValidPhoneNumber(custMobile)
                      ? "error"
                      : ""
                  }`}>
                    <PhoneInput
                      name="custMobile"
                      ref={register}
                      international
                      defaultCountry="IN"
                      placeholder="Enter Mobile Number"
                      value={formatPhoneNumberIntl(custMobile)}
                      onChange={(phone) => setCustMobile(phone)}
                    />
                    {formatPhoneNumberIntl(custMobile) && !isValidPhoneNumber(custMobile)?<span className="inputErrorMsg"> Invalid phone number </span>:""}
                  </div>
                </div>

                <div className="firstLastField d-flex">
                  <div
                    className={`inputWrap passwordField ${
                      errors.password ? "error" : ""
                    }`}>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      ref={register}
                    />
                    <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                        {passwordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.password && (
                      <span className="inputErrorMsg">
                        {errors.password?.message}
                      </span>
                    )}
                  </div>

                  <div
                    className={`inputWrap passwordField ${
                      errors.cpassword ? "error" : ""
                    }`}>
                    <input
                      type={cpasswordShown ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="cpassword"
                      ref={register}
                    />
                    <div className="passwordEye">
                      <i onClick={toggleCPasswordVisiblity}>
                        {cpasswordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.cpassword && (
                      <span className="inputErrorMsg">
                        {errors.cpassword?.message}
                      </span>
                    )}
                  </div>
                </div>  
                           
                {/* <div className="firstLastField d-flex"> */}
                  <div className= {`inputWrap ${
                      errors.srcLang ? "error" : ""
                    }`}>
                    <Controller
                      control={methods.control}
                      name="srcLang"
                      defaultValue={{}}
                      render={({ onChange, value, name, ref }) => (
                        <Select
                          inputRef={ref}
                          name={name}
                          value={srcLang}
                          onChange={OnChangeSourceLanguage}
                          options={srcLanguage}
                          isMulti={true}
                          isClearable
                          isSearchable={true}
                          placeholder={"Select Languages"}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.value}
                          ref={register}
                          //styles={customStyles}
                        />
                      )}
                    />
                    {errors.srcLang && (
                      <span className="inputErrorMsg">
                        {errors.srcLang?.message}
                      </span>
                    )}
                  </div>

                  <div className= {`inputWrap ${
                    errors.trascriberRole ? "error" : ""
                  }`}>
                    <Controller
                      control={methods.control}
                      name="trascriberRole"
                      defaultValue={{}}
                      render={({ onChange, value, name, ref }) => (
                        <Select
                          inputRef={ref}
                          name={name}
                          value={trascriberRole}
                          onChange={OnChangeTranscriberRole}
                          options={vendortypes}
                          isMulti={true}
                          isClearable
                          isSearchable={true}
                          placeholder={"Select Your Role"}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.value}
                          ref={register}
                          //styles={customStyles}
                        />
                      )}
                    />
                    {errors.trascriberRole && (
                      <span className="inputErrorMsg">
                        {errors.trascriberRole?.message}
                      </span>
                    )}
                  </div>
                {/* </div> */}
    
                <div
                  className={`partTimeWrap ${
                    errors.skype ? "error" : ""
                  }`}>
                    <input
                      type="checkbox"
                      name="parttime"
                      ref={register}
                      id="partTimeCheck"
                    />
                    <label htmlFor="partTimeCheck">Part Time?</label>    
                    <input
                      type="text"
                      placeholder="Hours Per Week"
                      name="hours"
                      ref={register}
                      style={{"width":"125px"}}
                      className="hrsInput"
                    />
                    <label className="noBorder">Hours Per Week</label> 
                </div>

                <div className="formFooter">
                  <button>Register</button>
                  <div className="loginLink">
                    Already a Member? <Link to="/">Sign In</Link>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default TranscriberRegister;
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";


// const schema = yup.object().shape({
//   reason: yup
//     .string()
//     .required("Reason is required !!!")
// });

const ProofReadSubmit = (props) => {
  debugger;
  const { allPeaks, datalist, id } = props;
  console.log("allPeaks in submit..",allPeaks);
  const [flag, setFlag] = useState()

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    //resolver: yupResolver(schema),
  });

  useEffect(()=>{
    for(let i =0; i<allPeaks.segments._segments.length; i++ ){
      if(allPeaks.segments._segments[i].isChecked == false || allPeaks.segments._segments[i].isChecked == undefined){
          setFlag(true);

          break;
      }else{
          setFlag(false);
      }
    }
},[flag])


  // use this hook to control the dialog
   const dialog = useDialog();


  // const IncompleteSubmitHandler = async(response) => {
  //   // e.preventDefault();
  //   // e.persist()
  //   debugger;
  //   let segmentarray = [];
  //   allPeaks.segments._segments.map((sdata, index) => {
  //     if(index == 0){
  //       let segmentdata = {
  //         id: sdata._id,
  //         startTime: sdata._startTime,
  //         segmentData: encodeURI((sdata._labelText).trim()),
  //         endTime: sdata._endTime,
  //         speakerId: sdata.speakerId,
  //         gender: sdata.gender?sdata.gender:"",
  //         agegroup: sdata.agegroup?sdata.agegroup:"",
  //         subsegments:sdata.SubSegments,
  //         sentiment:sdata.sentiment,
  //         isChecked:sdata.isChecked,
  //         speakerDetails:sdata.speakerDetails
  //         //speakerDetails:undefined
  //       };
  //       segmentarray.push(segmentdata);
  //     }else{
  //       let segmentdata = {
  //         id: sdata._id,
  //         startTime: sdata._startTime,
  //         segmentData: encodeURI((sdata._labelText).trim()),
  //         endTime: sdata._endTime,
  //         speakerId: sdata.speakerId,
  //         gender: sdata.gender?sdata.gender:"",
  //         agegroup: sdata.agegroup?sdata.agegroup:"",
  //         subsegments:sdata.SubSegments,
  //         sentiment:sdata.sentiment,
  //         isChecked:sdata.isChecked
  //       };
  //       segmentarray.push(segmentdata);
  //     }
  //   });
  
  //   console.log("Inside Submit Handler data ", response);
  //   const headers = reqHeader(true, true, true);
        
  //       let body={
  //         segmentarray,
  //         FileId: id,
  //         stage: datalist.stage,
  //         proofreadComment: response.reason,
  //       }
  //       console.log("body", body)
  //       const config = { headers };
  //       let url = `${process.env.REACT_APP_URL}/transcription/editor/submiteditorform`;
  
  //       const { data } = await axios.put(url, body, config);
  //       console.log("Submitted data", data);
  //       if (data.success) {
  //         toast.success(data.msg);
  //         window.close();
  //       }
    
  // };

  const CompleteSubmitHandler = async(response) => {
// e.preventDefault();
    // e.persist()
    debugger;
    console.log("flag", flag)
    if(flag === true){
      toast.error("Please make sure all segments are proofread and checkboxes are checked.");
    }else{
      let segmentarray = [];
      allPeaks.segments._segments.map((sdata, index) => {
        if(index == 0){
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked,
            speakerDetails:sdata.speakerDetails
            //speakerDetails:undefined
          };
          segmentarray.push(segmentdata);
        }else{
          let segmentdata = {
            id: sdata._id,
            startTime: sdata._startTime,
            segmentData: encodeURI((sdata._labelText).trim()),
            endTime: sdata._endTime,
            speakerId: sdata.speakerId,
            gender: sdata.gender?sdata.gender:"",
            agegroup: sdata.agegroup?sdata.agegroup:"",
            subsegments:sdata.SubSegments,
            sentiment:sdata.sentiment,
            isChecked:sdata.isChecked
          };
          segmentarray.push(segmentdata);
        }
      });
    
      console.log("Inside Submit Handler data ", response);
      const headers = reqHeader(true, true, true);
          
          let body={
            segmentarray,
            FileId: id,
            stage: datalist.stage
          }
          console.log("body", body)
          const config = { headers };
          let url = `${process.env.REACT_APP_URL}/transcription/editor/submiteditorform`;
    
          const { data } = await axios.put(url, body, config);
          console.log("Submitted data", data);
          if (data.success) {
            toast.success(data.msg);
            window.close();
          }
    }
    
  }

  
  return (
    <div className="SupportFilePopup">
    <form className="form" onSubmit={handleSubmit(CompleteSubmitHandler)}>
        <div className="addProjectDetails">
          <div className= "inputWrap rejectFormWrap">
            <label className="label">
             Do you want to submit the file? Make sure you have checked all segments before submitting. Once Submitted you will not be able to open the file again.
            </label>
          </div>
          <div className="popupFooter d-flex">
            <button className="orangeBtn btn" >Submit</button>
          </div>
        </div>
  </form>
</div>
)};

export default ProofReadSubmit;

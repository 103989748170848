export default function validate(
  value,
  segduration,
  spkid,
  lan,
  spkgender,
  spktype,
  sentiment
) {
  let errors = [];
  let segmentdata = [];

  //check Blank segment
  if (!value || value == "" || value == " ") {
    let SegmentDataEmpty = "Segment data is empty";
    segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
  }
  //more than 20 seconds check on segment length
  //debugger;
  if (segduration >= 20) {
    let TwentySecondError =
      "Segment is of more than twenty seconds duration, please split into smaller segments";
    segmentdata.push({ TwentySecondError: TwentySecondError });
  }

  //Check Space before period
  let spaceperiodregex = /\s[.|?|!]/;
  let SpaceBeforePeriod = spaceperiodregex.test(value);
  if (SpaceBeforePeriod) {
    SpaceBeforePeriod = "Space found before punctuation mark";
    segmentdata.push({ SpaceBeforePeriod: SpaceBeforePeriod });
  }

  ///4 seconds check on Standslone tags
  // let standalonetagregex = new RegExp(/^((\s*\[\S*\]\s*){1,})$/g);
  // let standalonetagsmatch = standalonetagregex.test(value);
  // let FourSecondStandaloneTags = "";
  // if (standalonetagsmatch) {
  //   if (segduration >= 4) {
  //     let FourSecondStandaloneTags =
  //       "Standalone tags of more than four seconds duration found";
  //     segmentdata.push({ FourSecondStandaloneTags: FourSecondStandaloneTags });
  //   }
  // }

  //Numerice value in text-area check
 // debugger;
  let numberregex = /[०-९]|[0-9]|[০-৯]|[૦-૯]|[೦-೯]|[൦-൯]|[୦-୯]|[੦-੯]|[௦-௯]|[౦-౯]|[۰-۹]/g
  //let numberregex = /\d/;
  let Overlapregex=/<OVERLAP\s*\S\d*>/g;
  let tempval=value;
  //console.log("tempval..",tempval);
  tempval=tempval.replace(Overlapregex,"");
  let numericValue = numberregex.test(tempval);
  
  if (numericValue) {
    //let Overlap=/\W\d/;
    numericValue = "Number in digits found";
    segmentdata.push({ numericValue: numericValue });
  }

  ////Special characters check
  //.!?,;:
  //debugger;
  //
  let newtext=value.replaceAll("</AW>","").replaceAll("</CP>","").replaceAll("<FIL/>","").replaceAll("</OVERLAP>","").replaceAll("</LM>","").replaceAll("</AW>","").replaceAll("</MP>","").replaceAll("</SUP>","").replaceAll("</NPS>", "").replaceAll("<UNIN/>", "").replaceAll("</MUSIC>", "").replaceAll(" ","").replaceAll("<UNKNOWN/>","");  

  let specialcharactersregex = /[\@#$%^&*()":{}\/`~;_\[\]]/g
  let SpecialCharacters = specialcharactersregex.test(newtext);

  if (SpecialCharacters) {
    if (!value.match(/([\,.'!?\-]+[\\]+\S*)/)) {
      SpecialCharacters = "Special characters found.";
      segmentdata.push({ SpecialCharacters: SpecialCharacters });
    }
  }

  ////Math symbols check
  //.!?,;:
  //debugger;
  //
  let mathsymbolssregex = /[\+\=]/g;
  let MathSymbols = mathsymbolssregex.test(value);

  if (MathSymbols) {
    MathSymbols = "Math symbols found.";
    segmentdata.push({ MathSymbols: MathSymbols });
  }

  // okay check
  if (value.match("Okay") || value.match("okay") || value.match("OKAY")) {
    let Okaystring = "Invalid string 'okay' found. Please repalce it with 'OK'";
    segmentdata.push({ Okaystring: Okaystring });
  }

  //debugger;
  //check openCloseMismatch
  let AWopen = 0,
    AWclose = 0,
    CPopen = 0,
    CPclose = 0,
    LMopen = 0,
    LMclose = 0,
    MPopen = 0,
    MPclose = 0,
    NPSopen = 0,
    NPSclose = 0,
    OVERLAPopen = 0,
    OVERLAPclose = 0,
    SUPopen = 0,
    SUPclose = 0,
    MUSICopen=0,
    MUSICclose=0,
    FILErrorFlag = false;

  let openCloseMismatchRegex = /[<]+\S.*?[>]/g;
  let getopenCloseString = value;
  let openCloseMismatch = openCloseMismatchRegex.test(value);
  const match = getopenCloseString.match(openCloseMismatchRegex);

  // console.log("value..",value);
  // console.log("openCloseMismatch..",openCloseMismatch);
  // console.log("match..",match);
  
  if (openCloseMismatch) {
    //debugger;
    match.map((match) => {
      if (getopenCloseString.includes("LM")) {
        if (match.includes("/LM")) {
          LMclose++;
        } else if (match.includes("LM")) {
          LMopen++;
        }
      }
      if (getopenCloseString.includes("AW")) {
        if (match.includes("/AW")) {
          AWclose++;
        } else if (match.includes("AW")) {
          AWopen++;
        }
      }
      if (getopenCloseString.includes("CP")) {
        if (match.includes("/CP")) {
          CPclose++;
        } else if (match.includes("CP")) {
          CPopen++;
        }
      }
      if (getopenCloseString.includes("MP")) {
        if (match.includes("/MP")) {
          MPclose++;
        } else if (match.includes("MP")) {
          MPopen++;
        }
      }
      if (getopenCloseString.includes("NPS")) {
        if (match.includes("/NPS")) {
          NPSclose++;
        } else if (match.includes("NPS")) {
          NPSopen++;
        }
      }
      if (getopenCloseString.includes("OVERLAP")) {
        if (match.includes("/OVERLAP")) {
          OVERLAPclose++;
        } else if (match.includes("OVERLAP")) {
          OVERLAPopen++;
        }
      }
      if (getopenCloseString.includes("SUP")) {
        if (match.includes("/SUP")) {
          SUPclose++;
        } else if (match.includes("SUP")) {
          SUPopen++;
        }
      }
      if (getopenCloseString.includes("MUSIC")) {
        if (match.includes("/MUSIC")) {
          MUSICclose++;
        } else if (match.includes("MUSIC")) {
          MUSICopen++;
        }
      }
    });
    if (
      AWopen != AWclose ||
      CPopen != CPclose ||
      LMopen != LMclose ||
      MPopen != MPclose ||
      NPSopen != NPSclose ||
      OVERLAPopen != OVERLAPclose ||
      SUPopen != SUPclose
    ) {
      openCloseMismatch = "Open close Angular tag mismatch found.";
      segmentdata.push({ openCloseMismatch: openCloseMismatch });
    }

    let angularemptyexpr = /[<]\S*[>]\s*[<]\S*[>]/g;
    // |[<]\S*[/>]
    //let spaceperiodregex = new RegExp(/(\s)\./);
    let emptyangulartags = angularemptyexpr.test(value);
    if (emptyangulartags) {
      let noerrorexpr = /[<\/]\S*[>]\s*[<]\S*[>]/g;
      if (!noerrorexpr) {
        emptyangulartags = "Empty angular tags found";
        segmentdata.push({ EmptyAngularTags: emptyangulartags });
      }
    }
  }

  ///validation for misplaced tags, close tag comes first
  let ntext = value.replaceAll("<FIL/>","").replaceAll("<SIL>","").replaceAll("<UNIN/>","").replaceAll("<UNKNOWN/>",""); 
  //let openClosematch = openCloseMismatchRegex.test(value);
  const matches = ntext.match(openCloseMismatchRegex);
  console.log("matches..",matches);
  let OC = [];
    
  if(matches && matches.length>0)
  {
    matches.map((match) => {
     
      // if (match.includes("/")) {
      //   OC.push("C");
      // } else {
      //   OC.push("O");
      // }
      //LM for LM,AW-AW,CP-CP,QT-MP,NS-NPS,RV-OVERLAP,ZU-SUP,WI-MUSIC
      if (match.includes("LM")) {
        if (match.includes("/LM")) {
          OC.push("M");
        } else if (match.includes("LM")) {
          OC.push("L");
        }
      }
      if (match.includes("AW")) {
        if (match.includes("/AW")) {
          OC.push("W");
        } else if (match.includes("AW")) {
          OC.push("A");
        }
      }
      if (match.includes("CP")) {
        if (match.includes("/CP")) {
          OC.push("P");
        } else if (match.includes("CP")) {
          OC.push("C");
        }
      }
      if (match.includes("MP")) {
        if (match.includes("/MP")) {
          OC.push("T");
        } else if (match.includes("MP")) {
          OC.push("Q");
        }
      }
      if (match.includes("NPS")) {
        if (match.includes("/NPS")) {
          OC.push("S");
        } else if (match.includes("NPS")) {
          OC.push("N");
        }
      }
      if (match.includes("OVERLAP")) {
        if (match.includes("/OVERLAP")) {
          OC.push("V");
        } else if (match.includes("OVERLAP")) {
          OC.push("R");
        }
      }
      if (match.includes("SUP")) {
        if (match.includes("/SUP")) {
          OC.push("U");
        } else if (match.includes("SUP")) {
          OC.push("Z");
        }
      }
      if (match.includes("MUSIC")) {
        if (match.includes("/MUSIC")) {
          OC.push("I");
        } else if (match.includes("MUSIC")) {
          OC.push("Y");
        }
      }
    });
    console.log("OC..",OC);
    if(OC)
    {
      for(let i=0; i<OC.length; i++)
      {
        //console.log("OC,OC length",OC,OC.length);
        //console.log("i..",i);
        //LM,AW,CP,QT,NS,RV,ZU,YI
        if((OC[i]=="L" && OC[i+1]=="M")||(OC[i]=="A" && OC[i+1]=="W")||(OC[i]=="C" && OC[i+1]=="P")||(OC[i]=="Q" && OC[i+1]=="T")||(OC[i]=="N" && OC[i+1]=="S")||(OC[i]=="R" && OC[i+1]=="V")||(OC[i]=="Z" && OC[i+1]=="U")||(OC[i]=="Y" && OC[i+1]=="I"))
        {
          OC.splice(i,2);
          i=-1;
          continue;
          //OC.splice(i+1,1);
        }
        //else if(OC[i]=="O" && OC[i+1]=="C")
        console.log("OC after splice..",OC);
      }
      console.log("final OC..",OC);
      if(OC&&OC.length>0)
      {
        let InvalidAngTag = "Invalid Angular tags found";
        segmentdata.push({ InvalidAngularTag: InvalidAngTag });
      }
      
    }
    
    
  }
  
  //debugger;
  //angular bracket mismatch
  let angularbracketregex = new RegExp(
    /([\<]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/>]+)/
  );
  /// |[\<]+\S.*?[\/>]+
  let Angulartag = angularbracketregex.test(value);
  let angTag = [
    "<AW>",
    "</AW>",
    "<CP>",
    "</CP>",
    "<FIL/>",
    "<LM>",
    "</LM>",
    "<MP>",
    "</MP>",
    "<NPS>",
    "</NPS>",
    // "<OVERLAP>",
    // "</OVERLAP>",
    "<SIL>",
    "<SUP>",
    "</SUP>",
    "<MUSIC>",
    "</MUSIC>",
    "<UNIN/>",
  ];
  //debugger;
  if (Angulartag) {
    let cntOpen = value.split("<").length - 1;
    let cntClose = value.split(">").length - 1;
    if (cntOpen != cntClose) {
      let AngularMismatch = "Incorrect Angular bracket found";
      segmentdata.push({ AngularMismatch: AngularMismatch });
    } else {
      // AngularMismatch = "";
      // segmentdata.push({ AngularMismatch: AngularMismatch });
      let angflag = false;
      let angtagmatches = value.match(
        /([\<]+\S.*?[\>]+)|([\<\/]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/\>]+)/g
      );
      console.log(angtagmatches);

      let InvalidAngularTag = "";
        //debugger;
      for (let i = 0; i < angtagmatches.length; i++) {
        if (angtagmatches[0] == "<") {
          angflag = true;
          break;
        }
        for (let j = 0; j < angTag.length; j++) {
          if (angtagmatches[i] === angTag[j] || angtagmatches[i].includes("OVERLAP")) {
            angflag = true;
            break;
          } else {
            angflag = false;
          }
        }
        if (!angflag)
          InvalidAngularTag = InvalidAngularTag + " " + "Invalid Angular tags";
      }

      if (InvalidAngularTag.match("Invalid Angular tags")) {
        InvalidAngularTag = "Invalid Angular tags found";
        segmentdata.push({ InvalidAngularTag: InvalidAngularTag });
      }
    }
    //letangularbracketregex2;
  }

  //debugger;
  // let angTagOrder = [
  //   "<AW>",
  //   "</AW>",
  //   "<CP>",
  //   "</CP>",
  //   "<LM>",
  //   "</LM>",
  //   "<MP>",
  //   "</MP>",
  //   "<NPS>",
  //   "</NPS>",
  //   // "<OVERLAP>",
  //   // "</OVERLAP>",
  //   "<SUP>",
  //   "</SUP>",
  //   "<MUSIC>",
  //   "</MUSIC>",
  //  ];

  //debugger;
  if (
    value.includes(" FIL") |
    value.includes("F I L") |
    value.includes("FI L") |
    value.includes("F IL")
  ) {
    //if (!value.includes("<FIL/>")) {
    let InvalidFILTag = "Invalid FIL tag(s) found";
    segmentdata.push({ InvalidFILTag: InvalidFILTag });
    //}
  }
  if (
    value.includes(" SIL") |
    value.includes("S I L") |
    value.includes("SI L") |
    value.includes("S IL")
  ) {
    //if (!value.includes("<SIL/>")) {
    let InvalidSILTag = "Invalid SIL tag(s) found";
    segmentdata.push({ InvalidSILTag: InvalidSILTag });
    //}
  }
  if (
    value.includes(" UNIN") |
    value.includes("U N I N") |
    value.includes("UN I N") |
    value.includes("U NIN") |
    value.includes("UN IN")
  ) {
    //if (!value.includes("<UNIN/>")) {
    let InvalidUNINTag = "Invalid UNIN tag(s) found";
    segmentdata.push({ InvalidUNINTag: InvalidUNINTag });
    //}
  }

  //debugger;
  //let temp1 = value.replaceAll("OK", "").replace("I", "").replace("A", "");
  let temp1 = value.replaceAll("OK", "");
  let newlmtgregex=/[A-Z]{2,}|[A-Z]+[a-z]+/g;
  //let LMregex=/LM/g;
  let angleregex=/<.*?>/g;
  let temp=temp1.replace(angleregex,"");
  console.log("temp", temp)

  //alert(temp);
  let newlmtg = newlmtgregex.test(temp);
    if(newlmtg)
    {
      let InvalidLMTag = "Invalid abbreviations or remove Uppercase letters in words if any.";
      segmentdata.push({ InvalidLMTag: InvalidLMTag });
    }
  //LM tag validation
  //debugger;
//find english text in any other lang segment text
if(lan!="English")
  { 
    let temp1=value;
    let islm=value.match( /([\<]+LM[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<\/]+LM[>]+)/g);
    console.log("Is LM found...",islm);
      if(islm && islm.length>0 && islm.includes("<LM>")){
        let lmTagVals = /<LM>.*?<\/LM>/g ;
        let lmregex = /<LM>[\s]*[a-zA-Z?.!,\'-\s]*[\s]*<\/LM>/;
                //let lmregex =/<LM>[\s][\S\s]*[\s]<\/LM>/g;
        let lmtag=true;
        var matchVal="";
        let temp2 = value.replaceAll("<SIL>", "").replaceAll("<FIL/>","").replaceAll("<UNIN/>","").replaceAll("</SUP>","").replaceAll("<SUP>","").replaceAll(/<OVERLAP\s*\S*>/g,"").replaceAll("</OVERLAP>","").replaceAll("</MUSIC>","").replaceAll("<MUSIC>","").replaceAll("<UNKNOWN/>","").replaceAll("<AW>","").replaceAll("</AW>","").replaceAll("<CP>","").replaceAll("</CP>","").replaceAll("<MP>","").replaceAll("</MP>","").replaceAll("<NPS>","").replaceAll("</NPS>","");
        
        console.log("if temp2..",temp2);
        matchVal=temp2.match(lmTagVals);
        console.log("matchVal..",matchVal);
        if(matchVal!==null && matchVal!==undefined && matchVal!=="" && lan!="English") 
          {   
            for(var i=0; i<matchVal.length; i++)
              {        
                  lmtag = lmregex.test(matchVal[i]); 
                  temp2=temp2.replaceAll(matchVal[i],""); 
                  if(i===(matchVal.length-1))
                  {
                    //console.log("if temp1..",temp2);
                    let engregex=/[a-zA-Z]/;
                    let otherlangengchars=engregex.test(temp2);
                    //console.log("otherlangengchars is",otherlangengchars);
                    if(otherlangengchars)
                    {
                      let InvalidLMTag = "Foreign language text without LM tag found";
                      segmentdata.push({ InvalidLMTag: InvalidLMTag });
                    }
                  }
              }
      }
      }
      else{
        let temp2 = value.replaceAll("<SIL>", "").replaceAll("<FIL/>","").replaceAll("<UNIN/>","").replaceAll("</SUP>","").replaceAll("<SUP>","").replaceAll(/<OVERLAP\s*\S*>/g,"").replaceAll("</OVERLAP>","").replaceAll("</MUSIC>","").replaceAll("<MUSIC>","").replaceAll("<UNKNOWN/>","").replaceAll("<AW>","").replaceAll("</AW>","").replaceAll("<CP>","").replaceAll("</CP>","").replaceAll("<MP>","").replaceAll("</MP>","").replaceAll("<NPS>","").replaceAll("</NPS>","");
        //console.log("else temp2..",temp2);
        let engregex=/[a-zA-Z]/;
        let otherlangengchars=engregex.test(temp2);
        //console.log("otherlangengchars is",otherlangengchars);
        if(otherlangengchars)
          {
            let InvalidLMTag = "Foreign language text without LM tag found";
            segmentdata.push({ InvalidLMTag: InvalidLMTag });
          }
       }            
  }

  let islm=value.match( /([\<]+LM[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<\/]+LM[>]+)/g);
  console.log("Is LM found...",islm);
  //debugger;
  if (islm && islm.length>0 && islm.includes("<LM>"))
    {
      let lmTagVals = /<LM>.*?<\/LM>/g ;
      let lmregex = /<LM>[\s]*[a-zA-Z?.!,\'-\s]*[\s]*<\/LM>/;
      //let lmregex =/<LM>[\s][\S\s]*[\s]<\/LM>/g;
      let lmtag=true;
      var matchVal="";
      let temp2 = value.replaceAll("<SIL>", "");
      temp2=temp2 && temp2.replaceAll("<FIL/>","");
      temp2=temp2 && temp2.replaceAll("<UNIN/>","");
      matchVal=temp2.match(lmTagVals);
      if(matchVal!==null && matchVal!==undefined && matchVal!=="" && lan!="English") 
      {   
      for(var i=0; i<matchVal.length; i++)
      {        
         lmtag = lmregex.test(matchVal[i]);         
        //  alert(lmtag+" "+matchVal[i]);         
         if(lmtag==false)  
         break;
      }
      }
      //debugger;
      if(!lmtag)
      {
      let InvalidLMTag = "Invalid LM tag(s), contains some invalid characters.";
      segmentdata.push({ InvalidLMTag: InvalidLMTag });
      }

      ////code for detecting invalid language text inside LM
      if(matchVal!==null && matchVal!==undefined && matchVal!=="") 
      {   
      for(var i=0; i<matchVal.length; i++)
      {        
        let text=matchVal[i].replace("<LM>","").replace("</LM>","");
        
          if(lan==="English")
          {
            let url = "";
            //let word="Hello";
            let engregex=/[a-zA-Z]/;
            let otherlangchars=engregex.test(text);
            console.log("otherlangchars is",otherlangchars);
            if(otherlangchars)
            {
              let InvalidLMTag = "Invalid LM tag(s), contains some invalid characters";
              segmentdata.push({ InvalidLMTag: InvalidLMTag });
            }
            
          }
          
        }
      }
     
     //debugger;
      let emptylmregex = /<LM><\/LM>/g;
      let emptylmtag = emptylmregex.test(value);
      //console.log("Empty LM tag found",emptylmtag)
      if(emptylmtag)
      {
        let InvalidLMTag = "Empty LM tag(s) found";
        segmentdata.push({ InvalidLMTag: InvalidLMTag });
      }

      
      // let lmspregex = /<LM>[\s][a-zA-Z]*[\s]<\/LM>/g;
      // let nosplmtag = lmspregex.test(value);
      // if(!nosplmtag)
      // {
      // let InvalidLMTag = "Invalid LM tag(s), please add a space before and after the word inside LM tag";
      // segmentdata.push({ InvalidLMTag: InvalidLMTag });
      // }
      //break;
    }

//validation for Empty tags
let lmTag = /<CP>.*?<\/CP>|<OVERLAP[\s]+[A-Z][0-9]+>.*?<\/OVERLAP>|<AW>.*?<\/AW>|<MP>.*?<\/MP>|<SUP>.*?<\/SUP>|<NPS>.*?<\/NPS>|<MUSIC>.*?<\/MUSIC>/g ; 
 
let lmmatchVal=value.match(lmTag);
if(lmmatchVal!==null && lmmatchVal!==undefined && lmmatchVal!=="") 
{   
for(var i=0; i<lmmatchVal.length; i++)
{  let flag=0;
   let OLregex=/<OVERLAP[\s]+[A-Z][0-9]+>/;      
   let newtext=lmmatchVal[i].replace("<CP>","").replace("</CP>","").replace(OLregex,"").replace("</OVERLAP>","").replace("<AW>","").replace("</AW>","").replace("<MP>","").replace("</MP>","").replace("<SUP>","").replace("</SUP>","").replace("<NPS>", "").replace("</NPS>", "").replace("<MUSIC>", "").replace("</MUSIC>", "").replace(" ","");         
  //  alert(lmtag+" "+matchVal[i]);         
   if(newtext.length==0||lmmatchVal===null || lmmatchVal===undefined ||lmmatchVal==="")  
   {
     flag=1;
   }
   if(flag)
   {
     let InvalidLMTag = "Blank tag found";
     segmentdata.push({ InvalidLMTag: InvalidLMTag });
     break;
 }
}
}
  
///validation for contains usually long word(s): <OVERLAP> <UNIN/> </OVERLAP> error
// debugger;
// let isoverlap=value.match( /([\<]+OVERLAP[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<\/]+OVERLAP[>]+)/);
// if(isoverlap)
// {
//   let longwordregex=/<OVERLAP>[\s][\S]{0,}[\s]<\/OVERLAP>/g;
//   let longtag = longwordregex.test(value);
//   if(!longtag)
//   {
//     let InvalidOVERLAPTag = "Invalid OVERLAP tag(s)found, either it is too long or missing spaces between tags";
//     segmentdata.push({ InvalidOVERLAPTag: InvalidOVERLAPTag });
//   }
// }
  // debugger;
  // if (
  //   (sentiment =
  //     "" ||
  //     sentiment == false ||
  //     sentiment == "defaultvalue" ||
  //     sentiment == "undefined")
  // ) {
  //   let SentimentEmpty = "Sentiment is not added for this segment";
  //   segmentdata.push({ SentimentEmpty: SentimentEmpty });
  // }
  debugger;
  console.log("spkid", spkid)
  if (
    (spkid ==
      "" || spkid == false || spkid == "defaultvalue" || spkid == "undefined")
  ) {
    let speakerIdEmpty = "Speaker Id is not added for this segment";
    segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
  }

  debugger;
  console.log("spkid", spkid)
  if((spkid=="OL"))
  {
    let isOverlapregex=/<OVERLAP\s*\S*>/g;
    let isOverlap = isOverlapregex.test(value);
    if(!isOverlap)
    {
      let speakerIdEmpty = "Overlap speaker id selected, but no Overlap tag found in segment";
      segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
    }
  }

  let isOLregex=/<OVERLAP\s*\S*>/g;
  let isOL = isOLregex.test(value);
  if(isOL)
  {
    if((spkid != "OL"))
   
    {
      console.log("spkid", spkid)
      let speakerIdEmpty = "Invalid Speaker Id selected";
      segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
    }
    
  }


  //Invalid Overlap tags
  //debugger;
  let isOverlapregex=/<OVERLAP\s*\S*>/g;
  let isOverlap = isOverlapregex.test(value);
  if(isOverlap)
  {
    let olmatch = value.match(isOverlapregex);
    olmatch.map(olm=>{
      let nolm=olm.replace("</OVERLAP>","");
      //debugger;
      if(nolm)
      {
        let validOLtagregex=/<OVERLAP[\s]+[A-Z][0-9]+>/g;
        let isregexpass=validOLtagregex.test(nolm);
        if(!isregexpass)
        {
          let Invalidoverlaptag = "Invalid Overlap tag found.";
          segmentdata.push({ Invalidoverlaptag: Invalidoverlaptag });
        }
      }
     
    });
  }

  debugger;
  let tempv=value;
  tempv=tempv.replaceAll("<CP>","").replaceAll("</CP>","").replaceAll("<UNIN/>","").replaceAll("<AW>","").replaceAll("</AW>","").replaceAll("<MP>","").replaceAll("</MP>","").replaceAll("<SUP>","").replaceAll("</SUP>","").replaceAll("<NPS>", "").replaceAll("</NPS>", "").replaceAll("<MUSIC>", "").replaceAll("</MUSIC>", "").replaceAll("<FIL/>","").replaceAll("<SIL>","").replaceAll("<LM>","").replaceAll("</LM>",""); 

  //let olr = /<OVERLAP[\s]+[A-Z][0-9]+>[\s]*[a-zA-Z?.!,\'-\s]*[\s]*<\/OVERLAP>/g;
  let olr = /<OVERLAP[\s]+[A-Z][0-9]+>[a-zA-Z\u0980-\u09FF\u0900-\u097F\u0A80-\u0AFF\u0C80-\u0CFF\u0D00 -\u0D7F\u0B00-\u0B7F\u0B80-\u0BFF\u0C00-\u0C7F\u0A00-\u0A7F?.!,\'-\s]*[\s]*<\/OVERLAP>/g;
  let isolr = olr.test(tempv);
    if(isolr)
    {
      let newtxt=tempv.replaceAll(olr,"");
      newtxt=newtxt.replaceAll(" ","");
      console.log("newtxt..",newtxt);
      if(newtxt!="")
      {
        let Invalidoverlaptag = "Invalid text found outside Overlap tag.";
          segmentdata.push({ Invalidoverlaptag: Invalidoverlaptag });
      }
      
    }

  //Minimum 2 overlap tag should be there in Overlap segment
  let OLregex=/<OVERLAP\s*\S*>/g;
  let isOLR = OLregex.test(value);
  if(isOLR)
  {
    let OLcount = (value.match(OLregex) || []).length;
    console.log("OLcount...",OLcount);
    if((OLcount<2))
    {
      let speakerIdEmpty = "Atleast 2 Overlap speakers should be there in Overlap segment";
      segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
    }
    
  }


  ///validation for other language word without LM tag
//debugger;
// let regexp=/[^\p{Common}]/g;

// switch(lan) {
//   case "Hindi":
//     regexp=/[^\p{Devanagari}]/g
//     break;
//   case "Tamil":
//     regexp=/[^\p{Tamil}]/g
//     break;
//   default:
//     regexp=/[^\p{Common}]/g
// }

// let foreigntag = regexp.test(value);
// if(foreigntag)
// {
//   console.log("Foreign language words found");
//   let islm=value.match( /([\<]+LM[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<\/]+LM[>]+)/);
//   if(!islm)
//   {
//     console.log("words without LM tag found");
//   }
// }

  // if (
  //   (spkgender =
  //     "" ||
  //     spkgender == false ||
  //     spkgender == "defaultvalue" ||
  //     spkgender == "undefined")
  // ) {
  //   let genderEmpty = "Gender is not added for this segment";
  //   segmentdata.push({ genderEmpty: genderEmpty });
  // }

  // if (
  //   (spktype =
  //     "" ||
  //     spktype == false ||
  //     spktype == "defaultvalue" ||
  //     spktype == "undefined")
  // ) {
  //   let agegroupEmpty = "Age group is not added for this segment";
  //   segmentdata.push({ agegroupEmpty: agegroupEmpty });
  // }

  errors.segmentdata = segmentdata;
  return errors;
}

import axios from "axios";
import React, { useEffect, useMemo, useState, useRef } from "react";
import "../../src/assets/style/normalize.css";
import reqHeader from "../helper/reqHeader.js";
// from "../Utils/ProgressBar.jsx";
import Table from "./Table/Table.jsx";
//import UploadLangDomain from "../Uploads/UploadLangDomain.jsx";
import { CustomDialog } from "react-st-modal";
//import moment from "moment";
// import {
//   DownloadIcon,
//   UploadIcon,
// } from "../../svgIconComponent/svg_icon_components";
//import DownloadFiles from "../Downloads/DownloadFiles";

const LangDomainList = (props) => {
  const {
    type,
    customer,
    setClickedLangDomain,
    langDomainDuration,
    totalDuration,
    progress,
    status,
  } = props;
  // console.log("customer..",customer);
  let title=customer.projectName;
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRowsValues, setSelectedRowsValues] = useState({});

  let columns = useMemo(
    () => [
      // {
      //   id: "_id",
      //   Header: "Id",
      //   accessor: "_id",
      //   sortType: "basic",
      //   filter: "text",
      // },
      {
        Header: "Language",
        accessor: "langDomainFreq",
        sortType: "basic",
        filter: "text",
        sticky: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              title="Click for the detailed File List"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original)}
            >
              {row.original.langDomainFreq}
            </div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "totalDuration",
        sortType: "basic",
        filter: "text",
        fixed: "left",
        hidden: !totalDuration,
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original, column)}
            >
              <span title="Click for the detailed Language Domain List">
                {`${row.original.duration}`}
              </span>
            </div>
          );
        },
      },
      // {
      //   Header: "Closure",
      //   accessor: "percentageOfCompletion",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <div
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         <ProgressBar completed={row.original.percentageOfCompletion} />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Progress / Total",
      //   accessor: "completedDuration",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !langDomainDuration,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {type === "track"
      //           ? `${row.original.completedDuration} / ${row.original.uploadedDuration}`
      //           : type === "assign"
      //           ? `${row.original.assignedDuration} / ${row.original.uploadedDuration}`
      //           : `${row.original.uploadedDuration} / ${row.original.duration} `}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !status,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {row.original.status}
      //       </div>
      //     );
      //   },
      // },

      // {
      //   Header: "Upload",
      //   accessor: "upload",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: type === "updown" ? false : true,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <button
      //         style={{ cursor: "pointer" }}
      //         title="Click for the detailed File List"
      //         className="uploadBtb"
      //         onClick={() => handleUpload(row.original)}
      //         disabled={row.original.status !== "Completed" ? false : true}
      //       >
      //         <UploadIcon />
      //         &nbsp; Upload
      //       </button>
      //     );
      //   },
      // },
      // {
      //   Header: "Download",
      //   accessor: "download",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: type === "updown" ? false : true,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <button
      //         className="downloadBtn"
      //         title="Click for the detailed File List"
      //         onClick={() => handleDownload(row.original)}
      //       >
      //         <DownloadIcon />
      //         &nbsp; Download
      //       </button>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    const getLanguageDomains = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/transcription/projectLanguageList`;
      const headers = reqHeader(true, true, true);
      const params = {
        customerID: customer.customerID,
        projectID: customer._id,
      };
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;

          setData(data);
        })
        .catch((err) => {
          setData([]);
        });
    };

    if (customer && Object.keys(customer).length !== 0) {
      getLanguageDomains();
    }
  }, [customer]);

  const handleClick = (row) => {
    debugger;
    if (row !== undefined) {
      setClickedLangDomain(row);
    } else {
      setClickedLangDomain({});
    }
  };

  // const handleUpload = async (row) => {
  //   let upldDetails = {
  //     domainID: row._id,
  //     id: row.projectID,
  //     projectName: row.projectName,
  //     customerID: row.customerID,
  //     custName: row.custName,
  //     custEmail: row.custEmail,
  //     custType: row.custType,
  //     custCode: row.custCode,
  //     srcLang: row.language,
  //     tgtFormat: row.language,
  //     isToolNeeded: row.isToolNeeded,
  //     ETA: row.ETA,
  //     domains: [
  //       {
  //         domainID: row._id,
  //         language: row.language,
  //         domain: row.domain,
  //         frequency: row.frequency,
  //         duration: row.duration,
  //         upDuration: row.uploadedDuration,
  //         folderPath: row.folderPath,
  //       },
  //     ],
  //     serviceType: "Transcription",
  //     tgtFormat: row.language,
  //   };

  //   await CustomDialog(
  //     <UploadLangDomain
  //       selectedRow={upldDetails}
  //       serviceType={upldDetails.serviceType}
  //       src="LangDomain"
  //     />,
  //     {
  //       title: "Upload Target File",
  //       showCloseIcon: true,
  //     }
  //   );
  // };

  // const handleDownload = async (row) => {
  //   await CustomDialog(<DownloadFiles selectedRow={row} />, {
  //     title: "File Downloads",
  //     showCloseIcon: true,
  //   });
  // };

  return (
    <div className="tableContainer">
      <div className="tableTopHeader">
        {/* <button className="table-btn">New</button>
        <button className="table-btn">Close</button> */}
        
      </div>
      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={false}
        expanded={false}
        title={title}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText="Domains"
        isMultiSelect={false}
        setSelectedRows={setSelectedRows}
        setSelectedRowsValues={setSelectedRowsValues}
      />
    </div>
  );
};

export default LangDomainList;

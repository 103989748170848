import React, { useState, useEffect, useMemo } from "react";
import { useHistory,Link  } from "react-router-dom";
import DataTable, { defaultThemes } from "react-data-table-component";
import FilterComponent from "./DataTableFilterComponent";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import Grouping from "../../helper/Grouping.js";
import { format } from "date-fns";
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";
import { toast } from "react-toastify";

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const columns = [
  {
    name: "ID",
    // selector: "id",
    selector: (row) => row.id,
    sortable: true,
    omit: true,
  },
  {
    name: "Customer",
    // selector: "title",
    selector: (row) => row.custName,
    sortable: true,
    wrap: true,
    // cell: (row) => <div style={{ fontWeight: 700 }}>{row.custName}</div>,
  },
  {
    name: "Project Name",
    // selector: "director",
    selector: (row) => row.projectName,
    sortable: true,
    // omit: hideDirector,
  },
  {
    name: "ETA",
    // selector: "year",
    selector: (row) => row.ETA,
    sortable: true,
    center: true,
    width: "150px",
    cell: (row) => <>{format(new Date(row.ETA), "dd/MM/yyyy")}</>,
  },
  // {
  //   name: "",
  //   selector: (row) => row.edit,
  //   sortable: true,
  //   right: true,
  //   ignoreRowClick: true,
  //   cell: (row) => (
  //     <>
  //       <button onClick={() => edited(row)} style={{ marginRight: "5px" }}>
  //         Edit
  //       </button>
  //       <button onClick={() => deleted(row)}>Delete</button>
  //     </>
  //   ),
  // },
];

const DataReactTable = (props) => {
  console.log("Props in DataReactTable is ", props);

  const { src } = props;

  let cols = useMemo(() => columns, []);
  // let datas = useMemo(() => data, []);
  let datas = useMemo(() => src, [src]);
  console.log("datas..",datas);

  const [dataList, setDataList] = useState(datas);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const filteredItems = datas.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );

    setDataList(filteredItems);
  }, [datas, filterText]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleRowClicked = (row) => {
    const updatedData = datas.map((item) => {
      if (row.id !== item.id) {
        return item;
      }

      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      };
    });

    setDataList(updatedData);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        //backgroundColor: "#FF8C69",
        //userSelect: "none",
      },
    },
  ];

  const ExpandableComponent = ({ data: rowData }) => {
    // <img src={data.image} alt={data.title} />

    console.log("ExpandableComponent datas rowData is ", rowData);

    const {
      id: projectID,
      projectName,
      customerID,
      custName,
      custEmail,
      subRows,
      subRowDetails,
    } = rowData;
    
    console.log("pid..",projectID);

    let subCols = [
      {
        name: "ID",
        // selector: "id",
        selector: (row) => row.id,
        sortable: true,
        omit: true,
      },
      {
        name: "Section",
        // selector: "title",
        selector: (row) => row.section,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <div>
            {/* <div style={{ fontWeight: 700 }}>{row.section}</div> */}
            <Link to={{pathname:"/admprfilelist",state:{pid:projectID,domain:row.domain,lang:row.name,freq:row.frequency}}}>
          <span style={{ fontWeight: 700 }}> {row.section}</span>
        </Link>
          </div>
        ),
      },
      // {
      //   name: "",
      //   selector: (row) => row.download,
      //   sortable: false,
      //   ignoreRowClick: true,
      //   center: true,
      //   width: "150px",
      //   omit: true,
      //   cell: (row) => (
      //     <>
      //       <button onClick={() => downloadJSON(row)}>Download All</button>
      //     </>
      //   ),
      // },
      // {
      //   name: "",
      //   selector: (row) => row.upload,
      //   sortable: false,
      //   ignoreRowClick: true,
      //   width: "250px",
      //   cell: (row) => (
      //     <>
      //       <input
      //         type="file"
      //         id="file"
      //         className="input-file"
      //         accept=".json"
      //         onChange={(e) => uploadErrorList(e.target.files[0], row)}
      //       />
      //     </>
      //   ),
      // },
      // {
      //   name: "",
      //   selector: (row) => row.download,
      //   sortable: false,
      //   ignoreRowClick: true,
      //   center: true,
      //   width: "150px",
      //   cell: (row) => (
      //     <>
      //       <button onClick={() => downloadJSONZip(row)}>Download</button>
      //     </>
      //   ),
      // },
      {
        name: "",
        selector: (row) => row.samples,
        sortable: false,
        ignoreRowClick: true,
        center: true,
        width: "150px",
        omit: true,
        cell: (row) => (
          <>
            <button onClick={() => samples(row)}>Samples</button>
          </>
        ),
      },
    ];

    // const downloadJSONZip = async (rows) => {
    //   console.log("inside the downloadJSONZip !!!");
    //   console.log("inside the downloadJSONZip row is ", rows);

    //   async function downloadAsZip() {
    //     const destination =
    //       custEmail.replace(/[^a-zA-Z0-9]/g, "_") || "undefined"
    //         ? custEmail.replace(/[^a-zA-Z0-9]/g, "_")
    //         : "Unknown";

    //     const url = `${process.env.REACT_APP_URL}/transcription/transcription/downloadaszip`;
    //     const headers = reqHeader(true, true, true);
    //     const params = {
    //       folderPath: rows.folderPath,
    //       destination: destination,
    //     };
    //     const config = { headers, params };

    //     await axios
    //       .get(url, config)
    //       .then((res) => {
    //         console.log("response is ", res);

    //         const { success, msg, data } = res.data;

    //         if (success === true) {
    //           const blob = b64ToBlob(data, "application/zip");
    //           fileSaver.saveAs(blob, `${rows.folderPath}.zip`);

    //           toast.success("Downloaded Successfully !!!");
    //         } else {
    //           toast.error(msg);
    //         }

    //         // const blob = new Blob([res.data], {
    //         //   type: "application/zip",
    //         // });

    //         // const url = window.URL.createObjectURL(blob);
    //         // const link = document.createElement("a");
    //         // link.download = "abcd.zip";
    //         // link.target = "_blank";
    //         // link.href = url;
    //         // // link.setAttribute("download", "Samples");
    //         // document.body.appendChild(link);
    //         // link.click();
    //       })
    //       .catch((err) => {});
    //   }

    //   if (rows.folderPath !== "" && rows.folderPath !== undefined) {
    //     downloadAsZip();
    //   } else {
    //     toast.error("Files not yet uploaded !!!");
    //   }
    // };

    // const downloadJSON = async (rows) => {
    //   console.log("inside the download JSON !!!");
    //   console.log("Rows inside the download JSON is ", rows);

    //   let rowDetails = subRowDetails.filter(
    //     (value) => value.tgtLang === rows.name
    //   );

    //   console.log("rowDetails inside the download JSON is ", rowDetails);

    //   if (rowDetails.length > 0) {
    //     rowDetails.map(async (value, index) => {
    //       const headers = reqHeader(true, true, true);
    //       let downloadUrl = `${process.env.REACT_APP_URL}/transcription/transcription/downloadFiles`;

    //       const params = {
    //         fpath: value.targetJsonFileLocation.replace(
    //           "/customerportal/",
    //           "/"
    //         ),
    //       };

    //       let fname = value.targetJsonFileName;

    //       const config = { headers, params };
    //       await axios
    //         .get(downloadUrl, config)
    //         .then((response) => {
    //           // console.log("response is ", response.data.data);
    //           // console.log("response.headers is ", response.headers);

    //           const { url } = response.data.data;

    //           const link = document.createElement("a");
    //           link.download = fname;
    //           link.target = "_blank";
    //           link.href = url;
    //           // link.setAttribute("download", "Samples");
    //           document.body.appendChild(link);
    //           link.click();
    //         })
    //         .catch((err) => {});
    //     });
    //   }
    // };

    const samples = async (rows) => {
      debugger;
      console.log("inside the samples !!!");
      console.log("inside the samples row is ", rows);

      async function downloadAsZip() {
        const destination =
          custEmail.replace(/[^a-zA-Z0-9]/g, "_") || "undefined"
            ? custEmail.replace(/[^a-zA-Z0-9]/g, "_")
            : "Unknown";

        const url = `${process.env.REACT_APP_URL}/transcription/transcription/samples`;
        const headers = reqHeader(true, true, true);
        const params = {
          folderPath: rows.folderPath,
          destination: destination,
        };
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            console.log("response is ", res);
          })
          .catch((err) => {});
      }

      if (rows.folderPath !== "" && rows.folderPath !== undefined) {
        downloadAsZip();
      } else {
        toast.error("Files not yet uploaded !!!");
      }
    };

    //let fileReader;

    // const uploadErrorList = (file, row) => {
    //   console.log("inside the uploadErrorList !!!");
    //   console.log("inside the uploadErrorList row is ", row);

    //   fileReader = new FileReader();
    //   fileReader.onloadend = (e) => handleFileRead(e, row);
    //   fileReader.readAsText(file);
    // };

    // const handleFileRead = (e, row) => {
    //   console.log("row inside the handleFileRead is ", row);

    //   const { name: language } = row;
    //   console.log("language inside the handleFileRead is ", language);

    //   const content = fileReader.result;
    //   // console.log("Content is ", content);
    //   // console.log("Content typeof is ", typeof content);

    //   const errors = content === null ? 0 : JSON.parse(content);
    //   // console.log("errors is ", errors);
    //   // console.log("errors typeof is ", typeof errors);

    //   if (errors.length > 0) {
    //     let groupedObj = Grouping(errors, ["fileName"]);

    //     // console.log("groupedObj is", groupedObj);

    //     let datas = [];
    //     let counter = 0;

    //     for (const item in groupedObj) {
    //       // console.log("Item inside the group is ", item);
    //       counter = counter + 1;

    //       let filterData = errors
    //         .filter((value) => {
    //           return value.fileName === item;
    //         })
    //         .sort((a, b) => (a.segment > b.segment ? 1 : -1));

    //       let fileDetails = subRowDetails.filter((value) => {
    //         return value.fileName === item && value.tgtLang === language;
    //       });

    //       // console.log("Item inside the filterData is ", filterData);

    //       let data = {
    //         id: counter,
    //         type: "parent",
    //         fileName: item,
    //         fileDetails: fileDetails,
    //         subRows: filterData,
    //       };

    //       datas = [...datas, data];
    //     }

    //     console.log("Datas is ", datas);

    //     datas = datas.sort((a, b) => (a.fileName > b.fileName ? 1 : -1));

    //     if (datas.length > 0) {
    //       history.push({
    //         pathname: "/errorlist",
    //         state: {
    //           customerID: customerID,
    //           custName: custName,
    //           projectID: projectID,
    //           projectName: projectName,
    //           errorList: datas,
    //         },
    //       });
    //     }
    //   }
    // };

    return (
      <>
        {/* <Table columns={subCols} data={subRows} filter={false} /> */}
        <DataTable
          title=""
          columns={subCols}
          data={subRows}
          keyField={"id"}
          defaultSortFieldId={2}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          selectableRows={false}
          onRowClicked={handleRowClicked}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader={false}
          subHeader={false}
          customStyles={customStyles}
        />
      </>
    );
  };

  return (
    <div>
      <DataTable
        title=""
        columns={cols}
        data={dataList}
        keyField={"id"}
        // defaultSortField={(row) => row.title}
        defaultSortFieldId={2}
        striped={true}
        highlightOnHover={true}
        pointerOnHover={true}
        selectableRows={false}
        expandableRows={true}
        expandableRowDisabled={(row) => row.expanderDisabled}
        expandableRowsComponent={ExpandableComponent}
        expandableRowsComponentProps={(row) => row.id}
        pagination={true}
        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
        paginationPerPage={10}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={true}
        subHeader={true}
        subHeaderComponent={subHeaderComponent}
        customStyles={customStyles}
      />
    </div>
  );
};

export default DataReactTable;
